/* ================================= */
/* LOCATIONS */
/* ================================= */
.location_section {
  margin-bottom: 10px;
}


.crosshair {
  cursor: crosshair;
}

.clickable {
  cursor: pointer;
}

.movable {
  cursor: move;
}

#map_container {
  position: relative;

  .marker {
    display: block;
    @media (max-width: $grid_s) {
      display: none;

      &:first-of-type {
        display: block;
      }

    }
  }

}

#map_container .marker, .marker_circle {
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #000000;
  border: 3px #FFF solid;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}
#map_container .marker:active {
  cursor: -webkit-grabbing;
}

#map_container .marker.active {
  background-image: url('/images/icons/marker_active.png');
}

#mapcontainer .marker.disabled {
  background-image: url('/images/icons/marker_disabled.png');
}
 

.marker_list {
  padding: 60px 4%;
  background: #183054;
  width: 100%;
  overflow: auto;
  font-size: 18px;
  line-height: 28px;
  @include box-sizing();

   @media (min-width: 1200px) {
      padding: 60px 50px;
   }

   @media (max-width: $grid_m) {
    font-size: 16px;
    line-height: 26px;
   }

  .marker_list_item {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  a {
    display: inline-block;
    position: relative;
    padding: 5px 10px;
    width: 100%;
    font-family: $brandon_regular;

    &:hover {
      background: #F2F2F2;
      color: $prim_color;
    }
  }
  .padding_mit {
      @include box-sizing();
      width:100%;
      padding-right: 30px;
      padding-left: 55px;
    }
  .marker_circle {
    display: inline-block;
    position: static;
    position: absolute;
    left: 0;
    top: 3px;
    width: 27px;
    height: 27px; 
    line-height: 27px; 
  }
} 

.mobile_location_list {
  text-align: center;
  padding: 20px;
  @include box-sizing();
  width:100%;
  h2 {
    text-align: center;
    color: #fff;

  }
  .collapse_header  {
    color: #fff;
    background-color: #1C2550;
    padding: 10px;
    margin-bottom: 0;
    border-bottom: 1px solid #333E76;
  }
  .collapse_content {
    font-size: 14px;
    padding: 15px;
    margin-bottom: 20px;
    background: #eee;
    overflow: hidden;
    position:relative;

    .marker_circle  {
      position: static;
      display: block;
      margin: 0 auto 15px;
      text-indent: center;
    }
  }

}

.location_selectbox {
    border: 2px solid #1C2550 !important;
    padding: 24px 47px 24px 20px;
    font-size: 17px;
    text-transform: uppercase;
    font-family: $brandon_bold;
}

.location_selectbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
    background-repeat: no-repeat;
    background-position: right 24px center;

}

.dark_overlay {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.7;
    left: 0;
    pointer-events: none;
}

// Qtip

.qTipPopUp {
  position: relative;
  z-index: 10;
}

.custom_qtip {
    background: transparent;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    border: 0;

  .qtip-content {
      padding: 17px;
      text-align: left;
      font-family: $brandon_regular;
      font-size: 14px;
      line-height: 18px;
  
      @include border-all-radius(5px);

      strong {
        display: block;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 5px;
        text-align: left;
        font-family: $brandon_bold;
        text-transform: uppercase;
      }
      .address {
        color: #8F8F8F;
        margin-top: 3px;

        span {
          margin: 0 5px;
        }
      }
  }
}

.qTipPopUp {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);

}

* html .qTipPopUp { margin-top: -100px;
    margin-left: -200px; }

.toot_text {
    position: absolute;
    bottom: 0;
    left: 40px;
    bottom: 36px;
    width: 370px;
    max-width: 100%;
    color: #fff;
    font-size: 16px;
    font-family: $monts;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 1);

    @media (max-width: $grid_s) {
      display: none;
    }

}

.marker_list_wrapper {
  font-size: 0px;
  line-height: 0px;
}

.location_gradient {
  background: url("/images/backgrounds/location_gradient.png");
  background-position: center bottom;
  background-repeat: repeat-x;
  height: 80px;
  right: 21px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  pointer-events: none;
}
/* ================================= */
/* Header */
/* ================================= */
/*
.header_fill {
  height   : 66px;
  position : relative;
  float    : left;
  width    : 100%;
}  */


#header_top, #mobile_top {
  background-color: #343235;
  padding: 10px 19px 11px 19px;
  width: 100%;
  box-sizing: border-box;
  font-family: $brandon_bold;

  .mobile_member_dash {
    margin-right: 10px !important;
  }

  .hide_number {
    @media (max-width: 600px) {
      display: none;
    }
    @media (max-width: 479px) {
      display: inline-block;
      margin-left: 10px;
    }
  }

  &.sticky {
    position: fixed;
    z-index: 99999;
  }

  .header_member {
    margin-right: 10px;
  }

  .header_member:hover {
        color: #939393;
  }

  a {
    color: #fffeff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

    i {
      margin-left: 2px;

      &:hover {
        color: #939393;
      }

    }

    &:first-of-type {


      @media (max-width: $grid_s) {
        margin-right: 0px;
      }

    }

    &.circle_link {
      border: 1px solid #fffeff;
      padding: 5px 22px;
      border-radius: 20px;
      text-transform: none;
      font-weight: normal;
      font-family: $proxima_smbd;

    &.phone_link {
        display: inline-block;
        padding: 0px 22px;
        line-height: 28px;

         @media (max-width: 600px) {
            float: right;
          }

           @media (max-width: 479px) {
            float: none;
            //margin-top: 6px;
          }

      .phone_icon {
        background-image: url('/images/icons/phone_icon.png');
        height: 7px;
        width: 17px;
        display: inline-block;
        margin-right: 10px;

        @media (max-width: 600px) {
          margin-right: 0px;
        }

      }

    }

      &:hover {
        background: #fffeff;
        color: $main_color;
      }

     img {
      margin-right: 6px;
      margin-bottom: 5px;
    }


    }
  }

    .social_ic {
      display: inline-block;

      @media (max-width: 377px) {
        display: block;
        margin-left: 20px;
      }

      a {
        margin-left: 15px;
        font-size: 17px;
      }

      ul {
        li {
          display: inline-block;
          list-style-type: none;
        }
      }
    }

    .member_login_link {
       @media (max-width: 312px) {
        display: block;
      }
    }

}

.phone_link {
  &:hover .phone_icon {
    background-image: url('/images/icons/phone_icon_hover.png') !important;
  }
}

#header {
  background-color: transparent;
  display:          block;
  width:            100%;
  float:            left;
  padding:          10px 0;
  position:         absolute;
  // border-bottom: 1px solid rgba(255,255,255,0.4);
  z-index: 9;
  @include transition();

  a.logo {
	margin:      10px 0px 0px 0px;
	padding:     0px;
	text-indent: -9999px;
	z-index:     2;
	height:      60px;
	width:       221px;
	display:     block;
	position:    relative;
	background:  url(/images/backgrounds/opera_alt_logo.png) top left no-repeat;

  @media(max-width: $grid_m) {
    background-size: contain;
    width: 171px;
    margin: 21px 0px 10px 0px;
    padding: 0px;
    text-indent: -9999px;
    z-index: 2;
    height: 52px;
  }

	@media (max-width: $grid_s) {
    margin-top: 15px;
	  height          : 36px;
	  width           : 140px;
	  background-size : 100% auto;
	  float           : left;
	  margin-bottom   : 0;
	}
  }
  .header_phone_number {
	float:       right;
	text-align:  right;
	padding-top: 42px;

	span {
	  padding:     10px 2px 10px 10px;
	  font-size:   27px;
	  font-weight: bold;
	}
	i {
	  font-size: 27px;
	}
  }

  @media (max-width: $grid_s) {
  	height  : 65px;
  	padding : 0;
  }

  &.sticky {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
    position: fixed;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
    z-index: 99999;
    top: 53px;
    a.logo {
      background: url('/images/logos/opera_logo.png') top left no-repeat;

      @media (max-width: $grid_m) {
         background-size: contain;
      }
    }

    ul#mainNav li a {
      color: #414143;
    }

    @media (max-width: $grid_s) {
      position:         static;
      top: 54px;

      a.logo {
        background-size: contain;
      }
    }
    @media (max-width: $grid_xs) {
      top: 89px;
    }
    @media (max-width: 312px) {
      top: 120px;
    }
  }
}

/* ================================= */
/* Navigation Menu DROP DOWN MENU */
/* ================================= */
/* Superfish Default */

ul#mainNav, ul#mainNav * {
  margin:     0;
  padding:    0;
  list-style: none;
  position:   relative;
}

ul#mainNav {
  text-align: right;
}

ul#mainNav li {
  position: relative; /* Change to position: static; for left aligned menu */
}

ul#mainNav li.sf-mega-wrap {
  position: static; /* OR put this class on the parent (of .sf-mega) li to left align */
}

ul#mainNav ul,
ul#mainNav .sf-mega {
  position: absolute;
  display:  none;
  top:      100%;
  left:     0;
  z-index:  99;
}

ul#mainNav .sf-mega ul {
  display:  block;
  float:    left;
  width:    100%;
  position: relative;
}


ul#mainNav li:hover > ul,
ul#mainNav li.sfHover > ul,
ul#mainNav  li:hover > .sf-mega,
ul#mainNav  li.sfHover > .sf-mega {
  display: block;
}

ul#mainNav a {
  display:  block;
  position: relative;
}

ul#mainNav ul ul {
  top:  0;
  left: 100%;
}

ul#mainNav .sf-mega {
  display:            none;
  position:           absolute;
  float:              left;
  top:                100%;
  left:               0;
  z-index:            99;
  width:              100%;
  -ms-box-sizing:     border-box;
  -o-box-sizing:      border-box;
  -moz-box-sizing:    border-box;
  -webkit-box-sizing: border-box;
  box-sizing:         border-box;
}

ul#mainNav .sf-mega ul ul {
  left:    0;
  display: none !important; /* Hides 3x level deep under mega */
}

ul#mainNav .sf-mega > ul > li > ul {
  opacity: 1 !important;
  display: block !important; /* Shows 2x level deep under mega */
}

/* Superfish Skin */
ul#mainNav {
  float:         left;
  margin-top: 15px;
  width:         100%;
}

ul#mainNav ul
ul#mainNav .sf-mega {
  min-width: 200px;
  *width:    100%;
  text-align: right;
}

ul#mainNav li {
  -webkit-transition: background .2s;
  transition:         background .2s;
}

ul#mainNav ul li {
  white-space:  nowrap;
  *white-space: normal;
}

ul#mainNav li:hover,
ul#mainNav li.sfHover {
  -webkit-transition: none;
  transition:         none;
}

ul#mainNav > li {
  display:   inline-block;
}

ul#mainNav > li > a {
  position:    relative;
  text-align:  center;
  display: inline-block;
  font-size:   12px;
  line-height: 22px;

  @media(min-width: $grid_xl) {
    font-size:   14px;
    line-height: 22px;
  }
  @media(max-width: $grid_m) {
    font-size:   9px;
    line-height: 22px;
  }
}

ul#mainNav a,
ul#mainNav a:visited {
  background:         transparentn;
  color:              #FFFFFF;
  padding:            14px 6px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration:    none;
  zoom:               1;
  display:            block;
  width:              100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  -ms-box-sizing:     border-box;
  box-sizing:         border-box;
  @include transition();

  @media(min-width: $grid_xl) {
    padding: 14px 10px;
  }

  @media(max-width: $grid_m) {
    padding: 14px 3px;
  }
}

ul#mainNav a:hover {
  opacity: 0.6;
}

ul#mainNav li:first-child a {
  border-left: 0;
}

ul#mainNav li.current span, ul#mainNav li a:hover span, .form_open span {
  border-bottom: 2px solid #cf181d !important;
}

ul#mainNav li span {
  border-bottom: 2px solid transparent;
}

ul#mainNav > li > a > span {
  display:            table-cell;
  vertical-align:     middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  -ms-box-sizing:     border-box;
  -o-box-sizing:      border-box;
  box-sizing:         border-box;
}

/* Drop downs */
ul#mainNav ul a,
ul#mainNav ul a:visited {
  border:     0;
  opacity: 1;
  min-width: 200px;
  padding:    8px 15px;
  background: rgba(255,255,255,0.5);
  color:      #373F65;
  font-size:  13px;
  text-align: left;
}

ul#mainNav ul a:hover {
  background: #FFFFFF;
  color:      #373F65;
}

/* Mega Menu */
ul#mainNav .sf-mega {
  width: 600px;
}

ul#mainNav .sf-mega,
ul#mainNav .sf-mega > ul {
  background-color: #EEEEEE;
}

ul#mainNav .sf-mega > ul {
  -ms-box-sizing:       border-box;
  -o-box-sizing:        border-box;
  -moz-box-sizing:      border-box;
  -webkit-box-sizing:   border-box;
  box-sizing:           border-box;
  float:                left;
  width:                33%;

  border-right:         1px solid #CCCCCC;
  border-width:         1px;
  border-right-style:   solid;
  -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from(#CCCCCC), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(#CCCCCC, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image:    -moz-linear-gradient(#CCCCCC, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image:      -o-linear-gradient(#CCCCCC, rgba(0, 0, 0, 0)) 1 100%;
  border-image:         linear-gradient(to bottom, #CCCCCC, rgba(0, 0, 0, 0)) 1 100%;
  border-left:          0;
}

ul#mainNav .sf-mega > ul:last-of-type {
  border-right: 0;
}

ul#mainNav .sf-mega > ul > li > a {
  font-weight:   bold;
  border-bottom: 1px solid #CCCCCC;
  color:         #000000;
}


/*
// Flexbox Menu
.flexbox ul#mainNav {
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  -ms-flex-wrap: no-wrap;

  display: -webkit-flex;
  -webkit-flex-wrap: no-wrap;
  -webkit-justify-content: space-between;

  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
}

.flexbox ul#mainNav > li {
  width: -ms-flex(1);
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.flexbox ul#mainNav > li > a {
  padding-left: 5px;
  padding-right: 5px;
}
*/

.contact_form_slide_out {
  background-color: #f1f3f4;
  padding: 75px 0px;

  h2 {
    margin-top: 11px;
    margin-bottom: 41px;

    @media(max-width: $grid_m) {
    }

  }

  p {
    font-family: $stempel_roman;
    font-size: 14px;
    line-height: 27px;

    &.contact_bullets {
      margin-left: 17px;
      span.bold {
        font-family: $stempel_b;
        margin-left: -17px;
      }
    }
  }

  input {
    margin-bottom: 10px;
  }

  .form_captcha {
    margin-right: 0px;
    width: 100% !important;
  }

  .submit_button {
    width: 100%;
    height: 87px;
    font-size: 16px;
  }

  .verification_code a {
    margin-left: 4px;

    @media(min-width: $grid_xl) {
      margin-left: 6px;
    }

  }

  .verification_code {
   @media(max-width: $grid_m) {
      margin-bottom: 30px;
    }
    @media(max-width: $grid_s) {
      margin-bottom: 20px;
    }
  }

  .first_section .second_section {
    @media(max-width: $grid_s) {
        margin-top: 10px;
    }
  }

}

.offCanvas_menu_social {
  li {
    i {
      font-size: 20px;
    }
  }
}

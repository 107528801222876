/* ================================= */
/* Gallery  */
/* ================================= */
.gallery_and_video_padding {
	margin: 190px 0 100px;

	@media(max-width: $grid_m) {
		margin: 100px 0 10px;
	}
	@media(max-width: $grid_s) {
		margin: 50px 0 0;
	}
}
/* General Gallery Styles & Fixes */
#content ul.gallery_cat_list, #content ul.thumbs {
  margin: 0;
}

#content .gallery_cat_list li, #content .gallery_thumbs li {
  text-indent: 0;
  margin-left: 0;
  padding:     0;

  &:before {
	display: none;
  }
}

/* Category Styles  */

#content .gallery_cat_list li {
  float:         left;
  width:         32%;
  position:      relative;
  display:       block;
  margin-bottom: 15px;

  a {
	float:              left;
	width:              100%;
	overflow:           hidden;
	background:         #000000;
	display:            block;
	-moz-transition:    all linear 100ms;
	-webkit-transition: all linear 100ms;
	-o-transition:      all linear 100ms;
	transition:         all linear 100ms;

	&:hover {
	  position: relative;
	  z-index:  99;

	  .gallery_title_overlay_container {
		width:      100%;
		top:        0;
		position:   absolute;
		z-index:    100;
		text-align: center;
		display:    table-cell;
	  }
	  .gallery_title_overlay {
		text-align: center;
		display:    inline-block;
		padding:    7px;
	  }
	  .gallery_title_label {
		color:          #FFFFFF;
		font-size:      20px;
		line-height:    24px;
		width:          100%;
		height:         215px;
		text-align:     center;
		vertical-align: middle;
		display:        table-cell;
	  }
	}
	img {
	  width:                       100%;
	  height:                      auto;
	  opacity:                     1;
	  -webkit-backface-visibility: hidden;
	  -moz-transition:             opacity .25s ease;
	  -webkit-transition:          opacity .25s ease;
	  transition:                  opacity .25s ease;
	}
	&:hover img {
	  opacity:  0.7;
	  width:    100%;
	  height:   100%;
	  position: relative;
	}
	&:active img {
	  opacity:  0.5;
	  width:    100%;
	  height:   100%;
	  position: relative;
	}
  }

  &.gallery_middle {
	margin-right: 2%;
	margin-left:  2%;
  }

  .gallery_title_overlay_container {
	display: none;
  }
  .gallery_title_overlay {
	display: none;
  }
  .gallery_divider {
	width:   100%;
	height:  0;
	padding: 0;
	margin:  0;
  }

  @media (max-width: $grid_m) {
	margin-bottom: 10px;

	a {
	  width: 100%;

	  &:hover {
		width: 100%;
		margin: 0;

		.gallery_title_label {
		  font-size: 14px;
		  line-height: 17px;
		  font-weight: bold;
		  height: 135px; /* Change the height to the height of the image */
		}
	  }
	}
  }

  @media (max-width: $grid_s) {
	margin-bottom: 15px;
	width: 48%;

	&.gallery_middle {
	  margin-right: 0;
	  margin-left: 0;
	}
	&.gallery_mob_last {
	  margin-left: 4%;
	}
	a {
	  background: none;

	  .gallery_title_overlay_container ,&:hover .gallery_title_overlay_container {
		position: relative;
		display: block;
		text-align: left;
	  }
	  .gallery_title_label {
		font-size: 14px;
		display: block;
		padding: 10px 0 2px 0;
		line-height: 17px;
		font-weight: bold;
		color: #000;
		text-align: left;
		height: auto; /* Change the height to the height of the image */
	  }
	  &:hover{
		.gallery_title_overlay {
		  padding: 0px;
		}
		.gallery_title_label {
		  font-size: 14px;
		  display: block;
		  padding: 10px 0 2px 0;
		  line-height: 17px;
		  font-weight: bold;
		  color: #000;
		  text-align: left;
		  height: auto; /* Change the height to the height of the image */
		}
	  }
	}
	.gallery_title_overlay {
	  display: block;
	  text-align: left;
	  padding: 0px;
	}
  }
}

/* THUMBS - ALBUM STYLES */
#content .gallery_thumbs {
  margin:  0;
  padding: 0;

  li {
	width:         24.2%;
	float:         left;
	margin-right:  1%;
	margin-bottom: 10px;;
	position: relative;
	z-index:  99;

	.gallery_title_overlay_container {
		@include transition();
	background-color: rgba(0,0,0,0.4);
	width:      100%;
	@include box-sizing();
	top:        0;
	position:   absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index:    100;
	text-align: center;
	}

	&:hover {
		.gallery_title_overlay_container {
			background-color:  rgba(0,0,0,0.0);
		}
		.gallery_title_label {
			opacity: 0.2;
		}
	}

	.gallery_title_label {
	text-transform: uppercase;
	color:          #FFFFFF;
	font-size:      48px;
	line-height:    52px;
	width:          100%;
	@include transition();
	text-align:     center;
	vertical-align: middle;

		@media(max-width: $grid_m) {
			font-size: 35px;
			line-height: 40px;
		}
		@media(max-width: $grid_s) {
			font-size: 24px;
			line-height: 30px;
		}
	}



	&.gallery_divider{
	  width:   100%;
	  height:  0;
	  padding: 0;
	  margin:  0;
	}
	a {
	  display:    block;
	  background: #000000;
	 
	}
	&.gallery_last {
	  margin-right: 0;
	}

	@media (max-width: $grid_m) {
	  &, &.gallery_last {
		width: 31.6%;
		margin-right: 1%;
	  }
	  .gallery_tab_last {
		margin-right: 0;
	  }
	}
	@media (max-width: $grid_s) {
	  &, &.gallery_last, &.gallery_tab_last {
		width: 48%;
		margin-right: 4%;
		margin-bottom: 18px;
	  }
	  &.gallery_mob_last {
		margin-right: 0;
	  }
	}
	@media (max-width: $grid_xs) {
	  &, &.gallery_last, &.gallery_tab_last {
		margin-bottom: 10px;
	  }
	}
  }
  .gallery_caption {
	padding: 15px 0 7px;

	h2 {
	  font-size:   14px;
	  color:       #000000;
	  line-height: 18px;
	}
	@media (max-width: $grid_s) {
	  .gallery_caption {
		padding: 18px 0 0px;
		h2 {
		  margin-bottom: 10px;
		}
	  }
	}
	@media (max-width: $grid_xs) {
	  .gallery_caption {
		padding: 10px 0 0px;
		h2 {
		  margin-bottom: 5px;
		}
	  }
	}
  }
}
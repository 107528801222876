/* ================================= */
/* Body and Universal Reset */
/* ================================= */

* {
  margin:  0;
  padding: 0;
}

html {
  background: #EEEEEE;
  min-height: 100%;
}

body {
  background: #FFFFFF;
  height:     auto;
  position:   relative;
}

.text_align_center_xs {
	@media (max-width: $grid_xs) {
		text-align: center !important;
	}
}

.break_s {
	@media (max-width: $grid_s) {
		margin-top: 10px; 
	}
}

.margin_bottom_s {
  @media (max-width: $grid_s) {
		margin-bottom: 7px; 
	}
}

#container {
}

#popupWindowCaller {
  display: none;
}

img {
  border: none;
}

::selection {
  background: #393E42;
  color:      #FFFFFF;
}

::-moz-selection {
  background: #393E42;
  color:      #FFFFFF;
}

@media (max-width: $grid_s) {
  html, body {
		font-size: 14px;
		line-height: 20px;
  }
  #homePage #menu_mobile {
		display : none;
  }

  .text_align_center_phone {
  	text-align: center !important;
  }
}
/* ================================= */
/* Defaults */
/* ================================= */

.white_text {

  &, h1, h2, h3, h4, p, a, a:visited, ul, ul li, li{
  	color: #fff;
  }
  a:hover, a:active {
  	color: #eee;
  }
}
.clear {
  width: 100%;
}

.collapse_header {
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.collapse_header:after {
  display:            inline-block;
  font-family:        "FontAwesome";
  font-weight:        normal;
  content:            "\f0d8";
  margin-left:        5px;
  float:              right;

  -webkit-transform:  rotate(0deg);
  -moz-transform:     rotate(0deg);
  -ms-transform:      rotate(0deg);
  -o-transform:       rotate(0deg);
  transform:          rotate(0deg);

  -webkit-transition: -webkit-transform ease 0.2s;
  -moz-transition:    -moz-transform ease 0.2s;
  -ms-transition:     -ms-transform ease 0.2s;
  -o-transition:      -o-transform ease 0.2s;
  transition:         transform ease 0.2s;
}

.collapse_header.closed:after {
  -webkit-transform: rotate(180deg);
  -moz-transform:    rotate(180deg);
  -ms-transform:     rotate(180deg);
  -o-transform:      rotate(180deg);
  transform:         rotate(180deg);
}

.no-csstransforms .collapse_header.closed:after {
  content: "\f0d7";
}

.vert_align_container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.251em; /* Adjust this for spacing */
}

.vert_align_container .vert_align {
	display: inline-block;
	vertical-align: middle;
	width: auto;
}

// vert center - alternative version - make sure the parent container has height - needed for video module as well */

.vert_center_container {
  width:   100%;
  display: table;
  height:  100%;

  .inner_vert_center_container {
	width:          100%;
	display:        table-cell;
	vertical-align: middle;
  }
}

.inner_t {
	padding-top: 30px;
}

.inner_b {
	padding-bottom: 30px;
}

/* ================================= */
/* Typography */
/* ================================= */

#content ul.noContent li:before, #content .noContent ul li:before, #content .flexslider ul li:before {
  content: none;
  display: none;
}

#content ul.noContent li, #content .noContent ul li, #content .flexslider ul li {
  text-indent: 0;
  margin-left: 0;
}

/* ================================= */
/* Off Canvas Menu */
/* ================================= */

html, body {
  background: #000;
}
.onCanvas {
  background: #FFFFFF;
/*   max-width: 2046px; */
  margin: 0 auto;
  float: none;

  @media(max-width: $grid_s) {
  	float: left;
  }
}

a.offCanvas_trigger {
  margin: 23px 0 0 0;
  height: 22px;
}
.offCanvas  {
	background: $second_prim;

	ul.offCanvas_menu > li > a {
		color: #fff;
		font-family: $monts;

		&:hover {
			color: #ca5a5d;
		}
	}
	ul.offCanvas_menu_social li a{
		color: #ffffff;

		&:hover {
			color: #ca5a5d;
		}
	}
}

.offCanvas_trigger.offCanvas_trigger_open .offCanvas_trigger_item,
.offCanvas_trigger_item {
  background: #173154;
}

/* ================================= */
/* Sidebar */
/* ================================= */

#content {
  #sidebar {
	margin-top: 15px;

	ul#subNav {
	  border-bottom:    solid 1px #E6E6E6;
	  background-color: #FFFFFF;
	  padding:          0;
	  margin:           0px 0px 15px 0px;

	  li {
		&:before {
		  content:      "\203A \00a0";
		  color:        $prim_color;
		  font-size:    15px;
		  margin-right: 5px;
		  line-height:  15px;
		}

		padding:      8px 0px 8px 32px;
		text-indent:  -15px;
		margin:       0px;
		border-top:   solid 1px #E6E6E6;
		border-left:  solid 1px #E6E6E6;
		border-right: solid 1px #E6E6E6;

		a {
		  color:           #000000;
		  padding:         5px 0px;
		  text-decoration: none;

		  &:hover {
			color: $prim_color;
		  }
		}

		&.down {

		  a {
			color:       $prim_color;
			font-weight: bold;

			&:before {
			  color:       $prim_color;
			  font-weight: bold;
			}
		  }
		}

		&.child {
		  border-top:   none;
		  padding-top:  4px;
		  padding-left: 32px;

		  &:before {
			font-size:   11px;
			margin-left: 14px;
		  }
		}

		&.childChild {
		  font-size:    11px;
		  border-top:   none;
		  padding-top:  4px;
		  padding-left: 50px;

		  &:before {
			font-size:   11px;
			margin-left: 28px;
		  }
		}

		&.subNavHeading {
		  padding:      11px 0px 8px 17px;
		  margin:       0px;
		  text-indent:  0px;
		  text-align:   left;
		  background:   $prim_color;
		  border-top:   1px solid #E5E5E5;
		  border-left:  1px solid #E5E5E5;
		  border-right: 1px solid #E5E5E5;

		  &:before {
			content: none;
		  }

		  a {
			color:           #FFFFFF;
			padding:         5px 0px 5px 0px;
			margin:          0px;
			font-weight:     bold;
			text-transform:  capitalize;
			text-decoration: none;
			font-size:       20px;

			&:hover {
			  color: #FFFFFF;
			}
		  }
		}
	  }
	}
  }
}

/* ================================= */
/* Content */
/* ================================= */

#content {
  width:          100%;
  display:        block;
}

.copy p, .copy table, .copy ul, .copy ol {
  margin: 0 0 18px 0;
}

.copy ul, .copy ol {
  margin-left: 18px;
}

/* ================================= */
/* Widgets */
/* ================================= */

.widget {
  margin-top:    15px;
  margin-bottom: 10px;

  .widget_title {
	padding:       10px 15px 10px 20px;
	background:    $prim_color;
	font-size:     13px;
	line-height:   17px;
	color:         #000000;
	margin-bottom: 0;

	h2 {
	  color:  #FFFFFF;
	  margin: 0;
	}
  }

  .widget_content {
	background: #E9E9E9;
	padding:    10px 15px 15px 20px;

	h2, h2 a {
	  font-size:     13px;
	  line-height:   17px;
	  color:         #000000;
	  margin-bottom: 0;
	}

	p {
	  margin-bottom: 10px !important;
	}

	ul {
	  margin-bottom: 0 !important;

	  li {
		font-weight:   bold;
		margin-left:   0px !important;
		margin-bottom: 5px;

		&:before {
		  margin-right: 3px;
		  font-size:    13px !important;
		}
	  }
	}
	.widget_date {
	  padding:          8px 0 13px;
	  margin:           4px 8px 10px 10px;
	  text-align:       center;
	  width:            72px;
	  background-color: $prim_color;
	  text-transform:   uppercase;
	  float:            right;
	  color:            #FFFFFF;
	  font-size:        22px;
	  font-weight:      bold;

	  h2 {
		font-weight:    bold;
		font-size:      30px;
		text-transform: none;
		color:          #FFFFFF;
		margin:         10px;
	  }
	}
	.blogDate {
	  color:         #000000;
	  font-style:    italic;
	  display:       block;
	  margin-bottom: 13px;
	  line-height:   14px;
	}
	.blogLink {
	  text-decoration: underline;
	}
	.widget_form_row {
	  margin:     3px 0;
	  text-align: right;

	  label {
		width:        21.5%;
		text-align:   left;
		margin-right: 2%;
		display:      inline-block;

		@media (max-width: $grid_m) {
		  width: 100%;
		  display: block;
		}
	  }
	}
	.widget_form_input {
	  text-align:    right;
	  width:         73%;
	  padding-right: 1%;
	  display:       inline-block;

	  @media (max-width: $grid_m) {
		width: 100%;
	  }
	}
	.widget_form_button {
	  margin-top:    7px;
	  padding-right: 1%;
	  text-align:    right;
	}
  }
}

.home_page .widget_content {
  min-height: 162px;
}

/* ================================= */
/* Sitemap */
/* ================================= */

#content ul.sitemap {
  list-style: none;
  margin:     0px;
  padding:    0px;

  li {

	&.sitemapHeading > a {
	  font-size:     16px;
	  padding:       10px 5px;
	  margin:        0px 0px 0px;
	  border-bottom: 1px solid #DDDDDD;
	  font-weight:   bold;
	  display:       block;

	  &:hover {
		background:    #EEEEEE;
		border-bottom: 1px solid #AAAAAA;
	  }
	}

	a {
	  padding: 5px 0px 3px;
	  display: block;
	}

	&:hover {
	  color: #000000;
	}
  }

}

.byLine {
  white-space: nowrap;

  span {
	position: relative;
	display:  inline-block;
	top:      -5px;
	margin:   0 3px 0;
  }
}

/* ================================= */
/* Breadcrumbs */
/* ================================= */

#content {

  #breadcrumbs {
  	display: none !important;
	display: block;
	float: left;
	width:   100%;
	background: #fafbfc;
	border-bottom: 1px solid #e1e2e3;

	ul {
	  list-style: none;
	  margin:     14px 0;
	  display:    block;
	  position:   relative;
	  float:      left;
	  width:      95%;

	  li {
		display: inline;
		padding: 0 5px 0 0;
		color: $copy_text_color;

		a {
		  padding:        0 5px 0 0;
		  color:          $copy_text_color;
		  font-size:      12px;

		  &:hover {
			color: $prim_color;
		  }
		  &.currentBreadcrumb {
			color:          #7d7d7d;
			text-transform: none;
		  }
		}
	  }
	}
  }

  span.breadcrumbDivider {
	color:       #888888;
	padding:     0;
	display:     inline-block;
	font-size:   13px;
	line-height: 12px;
  }
}

/* ================================= */
/* Enquiry Form */
/* ================================= */

.ten_margin {
	margin-top: 10px;
}

.required_fields_heading {
  font-size: 11px;
}

.form_required i, .required_fields_heading i {
  color: #FF0000;
}

.form_label, .form_required, .form_field {
  margin: 10px 0;
}

.form_label {
  width:   20%;
  padding: 7px 0 0 0;
  clear:   both;
}

.form_required {
  width:   5%;
  padding: 10px 0 0 0;
}

.form_field {
  width:      100%;
  min-height: 30px;
}

@media (max-width: $grid_s) {
  .form_label {
	width   : auto;
	padding : 0;
  }

  .form_required {
	width   : 5%;
	padding : 5px 0 0 10px;
  }

  .form_field {
	width      : 100%;
	float      : none;
	margin-top : 0;
  }
}

#content .errorInput {
  background:         url(/images/icons/error.gif) no-repeat right 3px #FFFFFF;
  -webkit-box-shadow: #EDC9C4 0 0 4px;
  -moz-box-shadow:    #EDC9C4 0 0 4px;
  box-shadow:         #EDC9C4 0 0 4px;
  border:             1px solid #ED5E56;
}

.imageVerification {

  * {
	vertical-align:  middle;
	margin:          0 0 0;
	text-decoration: none !important;
  }

  input,
  input[type="text"] {
  }
}

.errorText {
  color: #ED5E56;
}

input.hintText, .form_note {
  color: #999999;
}

/* ================================= */
/* Form Fields Inputs */
/* ================================= */

input, textarea, select {
  font-family: Arial, Helvetica, sans-serif;
  font-size:   12px;
  outline:     none;
  width:       100%;
}

input[type="text"], input[type="date"], input[type="email"], input[type="number"], input[type="url"], input[type="password"], textarea, select {
  width:                 100%;
  background: none;
  padding:               9px 17px;
  border:                2px solid #D2D8DD;
  @include border-all-radius(5px);
  box-shadow:            rgba(255, 255, 255, 0) 0 0 4px;
	color: #939598;
	font-family: $stempel_roman;
	font-size: 16px;
	line-height: 22px;
	vertical-align: center;
  transition:            box-shadow linear 0.2s, border linear 0.2s;
  @include box-sizing();

  &:focus {
		border: 2px solid $prim_color;
  }

	
}

/* ================================= */
/* Custom Checkboxes & Radio Buttons */
/* ================================= */

input[type="radio"].custom_input,
input[type="checkbox"].custom_input {
	display:none;

	.lt-ie9 & {
		width: auto;
		display: block;
		float: left;
		margin: 5px 10px 0px 0px;
	}

	& + label {
		display: block;
		padding-right: 16px;
		cursor: pointer;
		text-align: left;
		margin-bottom: 6px;

		&:before {
			content: "";
			display: block;
			width: 10px;
			height: 10px;
			background: #ffffff;
			border: 2px solid #ffffff;
			box-shadow: 0px 0px 0px 1px #BABABA;
			float: left;
			margin: 5px 10px 0px 0px;
			-webkit-transition: background 0.1s ease;
			-moz-transition: background 0.1s ease;
			-ms-transition: background 0.1s ease;
			-o-transition: background 0.1s ease;
			transition: background 0.1s ease;

			.lt-ie9 & {
				display: none;
			}
		}

		.no-boxshadow &:before {
			outline: 1px solid #BABABA;
		}

		&:hover:before {
			background: #BABABA;
		}
	}

	& + label.active:before,
	&:checked + label:before,
	&:checked + label:hover:before {
		background: $prim_color;
	}


	/* Large, boxy inputs */
	&.box {
		& + label {
			border: 1px solid #BABABA;
			padding: 10px;
		}

		& + label.active,
		&:checked + label,
		&:checked + label:hover {
			border-color: $prim_color;
		}
	}
}

input[type="radio"].custom_input + label:before {
	border-radius: 13px;
}

/* ================================= */
/* Tables */
/* ================================= */

table.invisibleTable tr:hover .mceLayout td {
  border-top: 1px solid #CCCCCC;
}

table.invisibleTable tr .mceLayout td table {
  margin: 10px 0 5px !important;
}

/* ================================= */
/* Buttons */
/* ================================= */

input[type="submit"], button, .button {
	padding: 10px 30px;
	border: 0px none transparent;
	display: inline-block;
	line-height: 1em;
	font-family: $monts;
	font-size: 11px;
	line-height: 20px;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	width: auto;
	font-weight: 700;
	margin-bottom: 3px;
	text-transform: uppercase;
	transition: all 0.2s ease;
	position: relative;
	letter-spacing: 0.9px;
	top: 0px;
	text-decoration: none !important;
	@include box-sizing();
	@include button_styles(transparent, #fff, #fff);
	@include border-all-radius(2px); 
	  @media(max-width: $grid_m) {
	  	  padding: 5px 30px;
	  }

  	img {
  		display: inline-block;
  		margin-left: 10px; 
  	}

	&:hover {
		@include button_styles(#fff,$prim_color, #fff);
	}

	&:active {
		top: 3px;
		outline: 0;
	}

	&:focus {
		outline: 0;
	}

	&.button_blue {
		font-family: $brandon_bold;
		@include button_styles($prim_color, #fff,$prim_color);

		&:hover {
			
			@include button_styles(#ffffff, $prim_color);
		}
	}

	&.button_red {
		@include button_styles($error_color);
	}

	&.button_white {
		@include button_styles(#ffffff, $prim_color);
	}

	&.white_button_transparent {
		@include button_styles(transparent, #ffffff, #fff);
		box-shadow: none;
		text-shadow: 0px 1px 0px rgba(0,0,0,0.3);
		margin-bottom: 0px;
		padding: 10px 27px;

		&:hover {
			background: rgba(255,255,255,0.5);
		}

		&:active {
			top: 0px;
		}
	}
	&.button_transparent {
		@include button_styles(transparent, $prim_color, $prim_color);
		box-shadow: none;
		margin-bottom: 0px;
		padding: 10px 27px;

		&:hover {
			@include button_styles($prim_color, #fff,$prim_color);
		}

		&:active {
			top: 0px;
		}
	}

	&.button_grey {
		@include button_styles(transparent, $second_prim, $second_prim);
		box-shadow: none;

		&:hover {
			@include button_styles($second_prim, #fff,$second_prim);
		}

		&:active {
			top: 0px;
		}
	}

	&.button_full_width {
		width: 100%;
		text-align: center;
	}

	&.button_small {
		padding: 4px 10px;
	}

	&.button_large {
		padding: 18px 22px;
	}

	&.button_xlarge {
		padding: 40px 52px;
		font-size: 16px;
	}

}

.button a,
.button a:hover,
.button a:active {
	color: inherit;
	text-decoration: none !important;
}

button.button {
	@extend .button;
}

.delete-button {
	color: $error_color;
}

/* ================================= */
/* Errors / Info / Caution */
/* ================================= */

.error_box, .info_box {
  margin:  0 0 15px 0;
  padding: 15px;
  color:   #555555;
}

.error_box {
  padding:          15px;
  background-color: #FFF0F1;
  margin-top: 20px;
  color:            #ED5E56;
  font-weight:      bold;
  text-align:       left;

  h3 {
	margin:         -15px -15px 0;
	padding:        15px;
	background:     #FFE4E4;
	text-transform: uppercase;
	font-size:      14px;
	color:          #ED5E56;
  }
  i {
	font-size:      17px;
	vertical-align: middle;
	margin-right:   10px;
	color:          #ED5E56;
  } ul li {
  	list-style: none;
  	font-size: 14px;
  }
}

.info_box {
	&.m_up {
		margin-top: 25px;
	}
  border-color:     #D5F296;
  background-color: #F2FCDC;
  padding:          15px;
  color:            #7F9E42;
  font-weight:      bold;
  text-align:       left;
  i {
	font-size:      17px;
	  vertical-align: middle;
	margin-right:   10px;
	color:          #7F9E42;
	margin-top:     -3px;
  }
}

#content .error_box ul {
  margin-bottom: 0;
  margin-top:    16px;
  font-size:     12px;
  margin-left:   3px;
}

/* ================================= */
/* Message Boxes */
/* ================================= */

.msg_box {
  position:     relative;
  margin:       20px 0;
  padding:      10px 10px 10px 45px;
  width:        640px;
  border-width: 2px;
  border-style: solid;
  /* border-radius: 5px; */
  color:        #555555;
  width: 100%;
  box-sizing: border-box;

  &.info {
	background:   #E1F2F7 url('/images/icons/information.png') no-repeat 15px 17px;
	border-color: #C5E4ED;
  }
  &.success {
	background:   #F2FCDC url('/images/icons/accept.png') no-repeat 15px 17px;
	border-color: #D5F296;
  }
  &.caution {
	background:   #FFFCD9 url('/images/icons/error.png') no-repeat 15px 17px;
	border-color: #F5F0AE;
  }
  &.error {
	background:   #FFF2ED url('/images/icons/exclamation.png') no-repeat 15px 17px;
	border-color: #F5C2AE;
  }
  a.close {
	position: absolute;
	top:      3px;
	right:    0px;
	padding:  5px 10px;
  }
}


/* ================================= */
/* Icons */
/* ================================= */

.small-icon {
  font-size: 14px;
}

.medium-icon {
  font-size:      22px;
  vertical-align: middle;
}

.large-icon {
  font-size:      35px;
  vertical-align: middle;
}

/* ================================= */
/* TinyMCE */
/* ================================= */

.editor {
  width: 100%;
}

.mini_editor {
}

#content .mceEditor table {
  margin: 0;
}

/* ====================================== MODULES ====================================== */
/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin:     0;
  padding:    0;
  list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
#content .flexslider ul, .flexslider {
  margin:  0 0 0;
  padding: 0;
}

.flexslider .slides > li {
  display:                     none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width:      100%;
  display:    block;
  max-height: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.flexslider li {
  position: relative;
}

.banner_text_wrapper {
  position: absolute;
  top:      20%;
  left:     0px;
  width:    100%;
  height:   80%;
}

.banner_text_wrapper .container_24 {
  position: relative;
}

.banner_text_wrapper .banner_text {
  position: relative;
  top:      0px;
  left:     0px;
}

.banner_text {
  text-align: left;
  display:    block;
  position:   absolute;
  top:        27%;
  left:       4%;
}

.banner_text h2 {
  font-size:   35px;
  line-height: 39px;
  color:       #000000
}

.banner_text p {
  font-weight:   bold;
  margin-bottom: 15px;
}

/* Clearfix for the .slides element */
.slides:after {
  content:     ".";
  display:     block;
  clear:       both;
  visibility:  hidden;
  line-height: 0;
  height:      0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:gallery_first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin:     0px;
  background: transparent;
  position:   relative;
  zoom:       1;
  overflow:   visible;
}

.flex-viewport {
  max-height:         2000px;
  -webkit-transition: all 1s ease;
  -moz-transition:    all 1s ease;
  transition:         all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom:   1;
  margin: 0;
}

.flexslider.multiple .slides li {
  margin-right: 20px;
}

.carousel li {
  margin-right: 5px
}

.flexslider ul, .flexslider ol {
  margin-bottom: 0 !important;
}

.flexslider ul li {
  margin-bottom: 0 !important;
  padding:       0 !important;
}

/* Cropped Slides */
/* Requires some tuning depending on the size of the banners you're working with. */

.flexslider.slide_crop li {
  overflow:   hidden;
  text-align: center;
}

.flexslider.slide_crop .slide {
  float:             none;
  display:           inline-block;
  height:            auto;
  width:             auto;
  min-width:         100%;
  max-width:         190%; /* Adjust this value to set the cutoff point */

  position:          relative;
  left:              50%;
  -webkit-transform: translateX(-50%);
  -moz-transform:    translateX(-50%);
  -ms-transform:     translateX(-50%);
  -o-transform:      translateX(-50%);
  transform:         translateX(-50%);
}

.no-csstransforms .flexslider.slide_crop  .slide {
  /* Center the banner for older browsers like IE8. */
  width:       1900px; /* Set to the slide width */
  left:        -950px; /* Half of the slide width */
  margin-left: 50%;
}

/* Direction Nav */
.flex-direction-nav li {
  position: static;
  height:   0px;
}

.flex-direction-nav a {
  width:       24px;
  height:      100%;
  margin:      0;
  padding:     0px 0px;
  display:     block;
  position:    absolute;
  z-index:     99;
  top:         0px;
  cursor:      pointer;
  text-indent: -9999px;
  opacity:     0;
  transition:  all .3s ease;
}

.flex-direction-nav .flex-next,
.direction_inside .flex-direction-nav .flex-next {
  background: url(/images/backgrounds/direction_nav_next.png) no-repeat center center;
  right:      0px;
}

.flex-direction-nav .flex-prev,
.direction_inside .flex-direction-nav .flex-prev {
  background: url(/images/backgrounds/direction_nav_prev.png) no-repeat center center;
  left:       0px;
}

.direction_outside .flex-direction-nav .flex-next {
  z-index: 99;
  right:   auto;
  left:    100%;
}

.direction_outside .flex-direction-nav .flex-prev {
  z-index: 99;
  left:    auto;
  right:   100%;
}

.direction_fixed .flex-direction-nav .flex-next,
.direction_fixed .flex-direction-nav .flex-prev {
  background-size: 12px 24px;
  width:           12px;
  height:          24px;
  top:             auto;
  bottom:          10%;
  right:           4%;
  left:            auto;
  opacity:         1;
  padding:         0px 10px;
}

.direction_fixed .flex-direction-nav .flex-prev {
  margin-right: 30px;
}

html.touch .flexslider .flex-next, html.touch .flexslider .flex-prev, /* Show buttons by default if the browser is touch enabled. */
.flexslider:hover .flex-next, .flexslider:hover .flex-prev {
  opacity: 1;
  padding: 0px 14px;
}

.flex-direction-nav .disabled {
  opacity: .3 !important;
  filter:  alpha(opacity=30);
  cursor:  default;
}

/* Control Nav */
.flex-control-nav, #copy .flex-control-nav {
  position:   absolute;
  z-index:    99;
  bottom:     10%;
  left:       4%;
  text-align: center;
  margin:     0px 5px;
}

.flex-control-nav.container_24, #copy .flex-control-nav.container_24 {
  position:   relative;
  z-index:    99;
  top:        -50px;
  bottom:     auto;
  bottom:     auto;
  left:       auto;
  text-align: center;
  margin:     0px auto;
  height:     0px;
  text-align: left;
}

.control_outside .flex-control-nav, #copy .control_outside .flex-control-nav {
  padding:  5px 0px;
  margin:   0px auto;
  position: relative;
  top:      auto;
  right:    auto;
  bottom:   auto;
  left:     auto;
}

.flex-control-nav li, #copy .flex-control-nav li {
  display:      inline-block;
  zoom:         1;
  *display:     inline;
  margin-left:  5px;
  margin-right: 5px;
}

.flex-control-paging li a {
  width:                 16px;
  height:                16px;
  display:               block;
  background:            #D6D6D6;
  cursor:                pointer;
  text-indent:           -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius:    20px;
  -o-border-radius:      20px;
  border-radius:         20px;
  box-shadow:            inset 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.flex-control-paging li a:hover {
  background: #EEEEEE;
}

.flex-control-paging li a.flex-active {
  cursor:     default;
  box-shadow: none;
  position:   relative;
  @include vertical-gradient($prim_color, darken($prim_color, 15%));
}

.flex-control-thumbs {
  margin:   5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width:  25%;
  float:  left;
  margin: 0;
}

.flex-control-thumbs img {
  width:   100%;
  display: block;
  opacity: .7;
  cursor:  pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .active {
  opacity: 1;
  cursor:  default;
}




// Form Row

.form_row {
  width:         100%;
  float:         left;
  position: relative;
  margin-bottom: 20px;

  	.form_row.dual.second {
		@media(max-width: $grid_xs) {
			margin-top: 15px;
		}
	}

  &.m_bottom, .m_bottom {
  	margin-bottom: 15px;
  }
  &.m_top, .m_top {
  	margin-top: 15px;
  }

  .form_label {
	width: 100%;
  }

  &.button_container {
  	float: right;
  	margin-right: 1%;
  	margin-top: 15px;
  	text-align: right;

  	@media(max-width: $grid_s) {
  		float: none;
  		text-align: center;
  	}
  }

  .tooltip_ic {
  	position: absolute;
	right: 21px;
	top: 31px;
  	font-size: 18px;
  	color: #D7D7D7;
  }

  label {
  	font-family: $brandon_regular;
    margin-bottom: 4px;
    margin-top: 0;
    display: block;
    text-transform: none;
    font-size: 17px;
    line-height: 22px;
  }
  .tagedit-list  {
  	@include box-sizing();
  }
  input {

	width: 100% !important;

	&.member_username {
	  padding-left: 45px;
	  background:   url('/images/icons/user-ic.png') 16px center no-repeat #FBFDFC;
	}
	&.member_password {
	  padding-left: 45px;
	  background:   url('/images/icons/lock-white-ic.png') 16px center no-repeat #FBFDFC;
	}
	&.disabled  {
	  background: #E4E4E4;
	  color: #3E5A4A;
	}
  }

  &.dual {
	width:        48%;
	margin-right: 2%;

	&.second {
	  margin-left:  1%;
	  margin-right: 0;
	}

  }

  &.triple {
	width:        31%;
	margin-right: 3.5%;

	input[type='checkbox'] {
	  width: auto!important;
	}

	&.last {
	  margin-left:  0%;
	  margin-right: 0;
	}
  }

  &.captcha {


	img, a {
	  margin: 5px 0;
	  float:  left;
	}
	a {
      margin: 6px 0 0 3%;
	  padding: 6px;
	  line-height: 15px;
      border: 2px solid $prim_color;
      @include border-all-radius(5px);

	  @media(max-width: $grid_s) {
	  	 margin: 6px 0 0 1%;
	  }
	  @media(max-width: $grid_xs) {
		margin-left: 12px;
		margin-right: 0px;
	  }

	}
	input{
	  width:       50% !important;
	  float:       left;
	  margin-left: 4% !important;
	}
	&.dual {
		input {
			width:       33% !important;
			float: right;

			@media(min-width: $grid_xl) {
				width:       42% !important;
			}
			@media(max-width: $grid_m) {
				width:       100% !important;
				margin-left: 0!important;
				margin-top: 10px;
			}
			@media(max-width: $grid_xs) {
				margin-top: 15px;
			}
		}
	}
  }
  @media (max-width: $grid_s) {
	&.captcha {

	  input {
		margin-top:  15px;
		width:       100% !important;
		margin-left: 0 !important;
	  }
	}

	&.triple {
	  width:        100%;
	  margin-right: 0;

	  input[type='checkbox'] {
		width: 60% !important;
	  }

	  &.last {
		margin-left: 0;
	  }
	}
  }

  @media (max-width: $grid_xs) {
	&.dual {
	  width: 100%;

	  &.second {
		margin-left: 0;
	  }

	}
  }
}
/* ================================= */
/* Members  */
/* ================================= */

.members_container {
  background-color:      #F9F9F9;
  padding:               30px;
  margin:                60px 0;
  -webkit-border-radius: 5px;
  -moz-border-radius:    5px;
  border-radius:         5px;

  h1 {
	text-align: center;
  }
}

.members_login_container {
  padding:      0 30px 0 0;

  &.right {
	border:  0;
	padding: 0 0 0 30px;
  }

  @media (max-width: $grid_s) {
	border-bottom: 1px solid #eee;
	border-right: 0;
	padding: 0 0 30px 0;
	margin-bottom: 30px;

	&.right {
	  padding: 0;
	}
  }
}

.members_options {
  margin-top: 10px;
  text-align: right;

  input {
    width: 100%;
    margin-top: 10px;
  }

  a:first-of-type {
	margin-right: 10px;
  }

  @media (max-width: $grid_s) {
	text-align: center;
	width: 100%;
	margin-top: 20px;
  }
}

.member_button {
  float: left;

  @media (max-width: $grid_s) {
	text-align: center;
  }
}

.member_home_container {
  -webkit-border-radius: 5px;
  -moz-border-radius:    5px;
  border-radius:         5px;
  margin-bottom:         35px;
  background:           darken($prim_color, 10%);
}

h1.members_login {
  margin:     0 -30px 30px;
  padding:    10px 0;
  font-size:  24px;
  background: $prim_color;
  color:      #FFFFFF;
}

.member_home_heading {
  width:      100%;
  margin:     30px 0 66px;
  text-align: center;

  &.sub_heading {
	margin:        24px 0 36px;
	padding:       0 0 10px;
	border-bottom: 0;
	i {
	  font-size: 69px;
	}
  }

  h1 {
	text-transform: capitalize;
	margin-bottom:  10px;

	+ p {
	  font-size:   14px;
	  line-height: 16px;
	}

	i {
	  margin:    0 auto 0px;
	  display:   block;
	  font-size: 90px;
	}
  }
}

.memeberbox_padding {
  padding: 20px 20px 0;
}

.memberbox {
  margin-bottom:               20px;
  display:                     inline-block;
  width:                       100%;
  background:                  $prim_color;
  padding:                     30px 30px 25px;
  -webkit-border-radius:       3px;
  -moz-border-radius:          3px;
  border-radius:               3px;
  -webkit-backface-visibility: hidden;
  -moz-transition:             background .25s ease;
  -webkit-transition:          background .25s ease;
  transition:                  background .25s ease;
  -webkit-box-sizing:          border-box;
  -moz-box-sizing:             border-box;
  -o-box-sizing:               border-box;
  -ms-box-sizing:              border-box;
  box-sizing:                  border-box;

  &:hover {
	background: darken($prim_color, 10%);

	.memberbox_title h2 {
	  margin-left: 20px;

	  @media (max-width: $grid_m) {
		margin-left: 0;
	  }
	  @media (max-width: $grid_xs) {
		margin-left: 0;
	  }
	}
  }
  &:active {
	background: darken($prim_color, 20%);
  }

  @media (max-width: $grid_xs) {
	padding: 20px 18px 25px;
  }
}

a .memberbox_icon {
  float:                       left;
  color:                       #FFFFFF;
  margin-right:                7%;
  margin-top:                  -5px;
  font-size:                   40px;
  -webkit-backface-visibility: hidden;
  -moz-transition:             all .25s ease;
  -webkit-transition:          all .25s ease;
  transition:                  all .25s ease;

  i {
	vertical-align: middle;
  }
  @media (max-width: $grid_m) {
	margin-top: -1px;
	font-size: 28px;
  }
  @media (max-width: $grid_xs) {
	margin-top: -1px;
	font-size: 28px;
  }
}

.memberbox_title h2 {
  font-size:                   18px;
  float:                       left;
  color:                       #FFFFFF;
  margin-bottom:               0;
  -webkit-backface-visibility: hidden;
  -moz-transition:             all .25s ease;
  -webkit-transition:          all .25s ease;
  transition:                  all .25s ease;
}

.memberbox_title h2 {
  font-size:                   18px;
  color:                       #FFFFFF;
  margin-bottom:               0;
  -webkit-backface-visibility: hidden;
  -moz-transition:             all .25s ease;
  -webkit-transition:          all .25s ease;
  transition:                  all .25s ease;
}
@media (max-width: $grid_s) {

  .password_button {
	width: 100%;
	text-align: center;
  }
  .sectioned_form {
  margin-top: 20px;
	margin-bottom: 20px;
  }
}
@media (max-width: $grid_xs) {
  .member_button_container, .members_login_container.right {
	text-align: center;
	width: 100%;
  }
  .member_button_container .button.grey, .member_button_container input[type=submit] {
	padding: 12px 10px!important;
  }
}

#membersBlock {

  h4 {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      letter-spacing: 2px;
  }

}

.member_sidebar {
  .member_dets {
    span {
      font-family: $stempel_b;
    }
  }
}
/* ================================= */
/* Blog */
/* ================================= */

.blog_template {
  margin-top: 100px;

  @media(max-width: $grid_m) {
    margin-top: 50px;
  }
  @media(max-width: $grid_s) {
    margin-top: 40px;
  }
}

.displaying {
  text-align:    center;
  margin-bottom: 10px !important;
}

.nextprevnav {
  display:    block;
  text-align: left;
  margin:     40px 0 0 -1px;
  font-size:  14px;
  padding:    0;

 a {
	text-decoration: none !important;
	color:           $prim_color;
  background: #F2F2F2;
  width: 30px;
  text-align: center;
  line-height: 30px;
  display:     inline-block;
  height: 30px;
  font-family: $brandon_medium_i;
  font-size: 18px;
  @include transition();

    &:hover {
      background: $prim_color;
      color: #fff;
    }
  }
}

.nextprevcurrentpage, .nextprevnav a {
  padding:     0;
  line-height: 30px;
  display:     inline-block;
  width: 30px;
  font-family: $brandon_medium_i;
  height: 30px;
  font-size: 18px;
  margin: 0 1px;

}

.nextprevnav a:hover {
  color: #fff !important;
}

.nextprevcurrentpage {
  color:                 #FAFBFD;
  background:            $prim_color;
  text-align: center;
}

.nextprevdisabled {
  color: #CCCCCC;
}

.nextprevnav .nextLink, .nextprevnav .prevLink {
  width:          60px;
  margin-left:    5px;
  margin-right:   5px;
  text-transform: uppercase;
  i {
	margin-left:  5px;
	margin-right: 5px;
  }
}

.blog_listing_result, #blogBanner .slide {
  height: 530px;
  @media(max-width: $grid_m) {
      height: 420px;
  }

  @media(max-width: $grid_xs) {
      height: 320px;
  }
}
.blog_listing_result {
  width: 94%;
  padding: 20px;
  display: block;
  @include box-sizing();
  position: relative;
  
  background-size: cover;
  background-position: center center;
  @include transition();

  @media(max-width: $grid_s) {
    width: 100%;
  }


  &:hover {
    opacity: 0.8;
  }


  .floating_date {
    position: absolute;
    right: 30px;
    top: 30px;
    color: #fff;
    padding: 3px 20px;
    text-align:  center;
    border: 3px solid #fff;

    span {
      font-size: 21px;
      line-height: 25px;
      text-transform: uppercase;
      font-family: $brandon_light;
    }
    h3 {
      margin-bottom: 0;
      font-size: 46px;
      line-height: 43px;
      color: #fff;
      text-transform: uppercase;
      font-family: $brandon_light;
    }
  }
  .floating_title {
    position: absolute;
    top: 70px;
    left:50px;
    h2 {
      font-size: 54px;
      line-height: 60px;
      font-family: $charter_bold;
      color: #fff;
      margin-bottom: 0;

      span {
        font-family: $charter_bold_i;
      }
    }
    @media(max-width: $grid_m) {
      bottom: 50px; 

      h2 {
        font-size: 44px;
        line-height: 46px;
      }
    }
    @media(max-width: $grid_s) {
      bottom: 40px; 
      left: 20px;
      right: 20px;

      h2 {
        font-size: 34px;
        line-height: 36px;
      }
    }
  }
  .floating_category {
    position: absolute;
    top: 50px;
    left:30px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 26px;
    font-family: $brandon_bold;
    padding-bottom: 45px;
    background: url('/images/backgrounds/blog-name.gif') bottom left no-repeat;

      @media(max-width:350px) {
         word-break: break-all;
      }
  }
  #blogBanner {
      position:absolute;
      top:0;
      right:0;
      left: 0;
      bottom: 0;
      height: 100%;
    .slide {
      display: block;  
      background-size: cover;
      background-position: center center;   
    }
    .slick-dots {
      position: absolute;
      left:0;
      bottom: 5px;
      right: 0;
      list-style: none;
      text-align:  center;

      @media(max-width: $grid_s) {
        bottom: -5px;
      }

      li {

        &:before {
          display: none;
        }

        position: relative;
        display: inline-block;
        width:  15px;
        height: 15px;
        margin: 0 12px;
        padding: 0;
        cursor: pointer;

        button {
          @include border-all-radius(50px);
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 0!important;
          width: 15px;
          height: 15px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: #fff;

          &:hover {
            background: #B2B2FA;
          }
        }
        &.slick-active button {
          background: #B2B2FA;
        }
      }
    }
  }
}
.extra_info {
  margin-right: 6%;
  margin-bottom: 50px;
  font-family: $brandon_medium;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;

  @media(max-width: $grid_s) {
    margin-right: 0;
  }

  p {
    text-transform: none;
    font-size: 18px;
    font-family: $brandon_regular;
  }

  .full_date {
    text-align: right;
  }
  .blog_title {
    h2 {
      margin: 30px 0px;
      background: none;
      padding-bottom: 0px;
      font-size: 26px;
      line-height: normal;
    }
  }
  a.read_more {
    border: 1px solid #343943;
    padding: 11px 27px;
    margin-top: 26px;
    display: inline-block;
  }
}

.blog_content_padding {
  margin-right: 6%; 
    @media(max-width: $grid_s) {
    margin-right: 0;
  }

}
// Blog Sidebar

#content .blog_sidebar {
  margin-bottom: 40px;
  h3 {
    font-family: $brandon_bold;
    font-size: 14px;
    line-height: 18px;
  }
  ul {
    margin-left: 0!important;

    li {
      border-top: 1px solid #d7d4d4;
      text-indent: 0;
      margin-left: 0;
      a {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  @media(max-width: $grid_s) {
    text-align: center;

    &:last-child {
      margin-bottom: 10px;
    }
  }
}
@mixin button_styles($background_color, $button_color: #ffffff, $border-color: #fff) {


	@if ($background_color == transparent) {
		background-color: transparent;
	}

	@else {

		@if ($button_color == null) {
			@if (lightness($background_color) > 50) {
				$button_color: #000000;
			} @else {
				$button_color: #ffffff;
			}
		}

		@if (lightness($background_color) > 50) {
			border: 1px solid $border-color;
		} @else {
			border: 1px solid $border-color;
		}

		background-color: $background_color;
	}

	border: 1px solid $border-color;

	&, &:visited {
		color: $button_color;
	}

	&:hover {
		color: $button_color;
		background-color: $border-color;
	}

	&:active {
		color: $button_color;
		background-color: $background_color;
		border: 1px solid $border-color;
	}
}
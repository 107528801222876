/*
	Colorbox
*/
#cboxOverlay,#cboxWrapper,#colorbox{position:absolute;top:0;left:0;z-index:9999;overflow:hidden}#cboxWrapper{max-width:none}#cboxOverlay{position:fixed;width:100%;height:100%}#cboxBottomLeft,#cboxMiddleLeft{clear:left}#cboxContent{position:relative}#cboxLoadedContent{overflow:auto;-webkit-overflow-scrolling:touch}#cboxTitle{margin:0}#cboxLoadingGraphic,#cboxLoadingOverlay{position:absolute;top:0;left:0;width:100%;height:100%}#cboxClose,#cboxNext,#cboxPrevious,#cboxSlideshow{cursor:pointer}.cboxPhoto{float:left;margin:auto;border:0;display:block;max-width:none;-ms-interpolation-mode:bicubic}.cboxIframe{width:100%;height:100%;display:block;border:0;padding:0;margin:0}#cboxContent,#cboxLoadedContent,#colorbox{box-sizing:content-box;-moz-box-sizing:content-box;-webkit-box-sizing:content-box}

/* ================================= */
/* Colorbox Theme */
/* ================================= */
#cboxOverlay {
  background: #000000;
  opacity:    0.9;
  filter:     alpha(opacity=90);
}

#colorbox {
  outline: 0;
}

#cboxContent {
  margin-top: 32px;
  overflow:   visible;
}

.cboxIframe {
  background: #FFFFFF;
}

#cboxError {
  padding: 50px;
  border:  1px solid #CCCCCC;
}

#cboxLoadedContent {
  background: #FFFFFF;
  padding:    1px;
}

#cboxLoadingGraphic {
  background: url(../../images/colorbox/loading.gif) no-repeat center center;
}

#cboxLoadingOverlay {
  background: #000000;
}

#cboxTitle {
  position: absolute;
  top:      -22px;
  left:     0;
  color:    #000000;
}

#cboxCurrent {
  position:    absolute;
  top:         -22px;
  right:       205px;
  text-indent: -9999px;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border:      0;
  padding:     0;
  margin:      0;
  overflow:    visible;
  text-indent: -9999px;
  width:       25px !important;
  height:      25px;
  position:    absolute;
  top:         -25px;
  background:  url(../../images/colorbox/controls.png) no-repeat 0 0;
  padding:     0 !important;
}

button#cboxPrevious, button#cboxNext, button#cboxSlideshow, button#cboxClose {
  border:             none !important;
  outline:            none !important;
  -webkit-transition: none !important;
  -ms-transition:     none !important;
  -moz-transition:    none !important;
  -o-transition:      none !important;
  transition:         none !important;
  background-color:   none;
  -webkit-box-shadow: none;
  -moz-box-shadow:    none;
  -o-box-shadow:      none;
  -ms-box-shadow:     none;
  box-shadow:         none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0;
}

#cboxPrevious {
  background-position: 0px 0px;
  right:               44px;
}

#cboxPrevious:hover {
  background-position: 0px -25px;
}

#cboxNext {
  background-position: -25px 0px;
  right:               22px;
}

#cboxNext:hover {
  background-position: -25px -25px;
}

#cboxClose {
  background-position: -50px 0px;
  right:               0;
}

#cboxClose:hover {
  background-position: -50px -25px;
}

.cboxSlideshow_on #cboxPrevious, .cboxSlideshow_off #cboxPrevious {
  right: 66px;
}

.cboxSlideshow_on #cboxSlideshow {
  background-position: -75px -25px;
  right:               44px;
}

.cboxSlideshow_on #cboxSlideshow:hover {
  background-position: -100px -25px;
}

.cboxSlideshow_off #cboxSlideshow {
  background-position: -100px 0px;
  right:               44px;
}

.cboxSlideshow_off #cboxSlideshow:hover {
  background-position: -75px -25px;
}
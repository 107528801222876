// All side border-radius

@mixin vertical-gradient($bottom_colour, $top_colour) {
  background: $bottom_colour; /* Old browsers */
  background: -moz-linear-gradient(top,  $bottom_colour 0%, $top_colour 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,$bottom_colour), color-stop(100%,$top_colour)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $bottom_colour 0%,$top_colour 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $bottom_colour 0%,$top_colour 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $bottom_colour 0%,$top_colour 100%); /* IE10+ */
  background: linear-gradient(top,  $bottom_colour 0%,$top_colour 100%); /* W3C */
}

.video_thumbs_outer_container {

	margin: 10px -10px;

	.video_thumb {
		@media(max-width: $grid_xs) {
			text-align: center;	
		}

		a {
			display: block;
			width: 100%;
			height: 200px;
			background-size: cover;
			background-position: center center;
			text-align: center;
			@include transition();

			&:hover {
				opacity: 0.8;
			}

			@media(max-width: $grid_m) {
				height: 130px;
			}
			@media(max-width: $grid_s) {
				height: 160px;
			}
			@media(max-width: $grid_xs) {
				height: 160px;
			}
		}
		.video_info {
			margin-top: 20px;

			h3 {
				font-family: $brandon_bold;
				line-height: 22px;
			}
		}
	}

	// Clears for mobile & desktop
	.video_desktop_clear, .video_s_clear {
		clear: both;
		display: block;
		overflow: hidden;
		visibility: hidden;
		width: 100%;
	}
	.video_desktop_clear {
		@media(max-width: $grid_s) {
			display: none;
		}
	}
	.video_s_clear {
		display: none;
		@media(min-width: $grid_s_min) and (max-width: $grid_s_max) {
			display: block;
		}
	}
}
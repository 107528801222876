.full_bg {
	background-color: #f1f3f4;
}

// .copy {
// 	padding-top: 80px;
// }

.full-width .secondary_banner {
	margin-bottom: 18px;
}

/* Secondary Page Banner */
.secondary_banner {
	display: block;
	float: left;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-position-x: center !important;
	background-color: #000;

	-webkit-perspective: 800px;
	-webkit-perspective-origin: 0% 50%;
	perspective: 800px;
	perspective-origin: 0% 50%;

	&, .vert_center_container {
		height: 513px;
		margin-bottom: 10px;

		@media (max-width: $grid_m) {
			height: 500px;
		}
		@media (max-width: $grid_s) {
			height: 400px;
		}
	}

	.banner_heading_container {
		margin-top: 102px;

		.no-cssanimations & {
			opacity: 1;
		}

		opacity: 0;

		-webkit-transform: rotateY(45deg);
		-webkit-transform-origin: center left;

		transform: rotateY(45deg);
		transform-origin: center left;

		-webkit-animation-name: FlipFadeIn;
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: forwards;
		-webkit-animation-timing-function: ease-out;

		animation-name: FlipFadeIn;
		animation-duration: 1s;
		animation-fill-mode: forwards;
		animation-timing-function: ease-out;

		@media (max-width: $grid_m) {
			padding-left: 30px;
		}
		@media (max-width: $grid_s) {
			padding-left: 30px;
			margin-top: 65px;
		}
		@media (max-width: $grid_xs) {
			text-align: center;
			padding-left: 0;
		}
		h1 {
			color: #fff;
			font-size: 100px;
			margin-bottom: 60px;
			line-height: 100px;
			text-transform: none;

			@media (max-width: $grid_m) {
				font-size: 80px;
				margin-bottom: 30px;
				line-height: 72px;
			}
			@media (max-width: $grid_xs) {
				font-size: 50px;
				margin-bottom: 30px;
				line-height: 48px;
			}
			span {
				display: block;
			}
		}
	}

	@media (max-width: $grid_m) {
		background-size: cover;
	}
}

@keyframes FlipFadeIn {
    0% {
    	transform: rotateY(60deg);
    }

    50% {
    	opacity: 0;
    }

    100% {
    	opacity: 1;
    	transform: rotateY(0deg);
    }
}

@-webkit-keyframes FlipFadeIn {
    0% {
    	-webkit-transform: rotateY(60deg);
    }

    50% {
    	opacity: 0;
    }

    100% {
    	opacity: 1;
    	-webkit-transform: rotateY(0deg);
    }
}

.the_register_strip {
	padding: 40px 0 35px; 
	background: #F1F3F4;
	text-align: center;

	&.white {
		background: #fff;
	}

	span {
		text-transform: uppercase;
		vertical-align: middle;
		color: $prim_color;

		@media (max-width: $grid_s) {
			display: block;
			width: 100%;
			margin-bottom: 20px;
		}
	}

	.button {
		margin-left: 35px;
		margin-top: -5px;

		@media (max-width: $grid_s) {
			margin-left: 0;
		}
	}
}

.form_row label {
	@media (max-width: $grid_xs) {
	    text-align:left;
	}
}

.member_pages {
	margin: 80px 0px; 

}

.form_info {
	background-image: url('/images/icons/info.png');
	width: 44px;
	height: 44px;
	display: inline-block;
}
.form_refresh {
	background-image: url('/images/icons/refresh.png');
	width: 44px;
	height: 44px;
	display: inline-block;
}

#member-update-profilePage {

	.copy ul li:before, .copy ol li:before {
		    border: 2px solid #cd181d;
	}

}

#cleaningForm,
#serviceForm,
#maintenanceForm {
	h4 {
		margin-top: 10px;
	    margin-bottom: 10px;
	}

	input[type=checkbox] {
		width: auto;
	

	}
}

.checkbox_small {
	margin-top: 30px;
	input {
		margin-top: -10px;
	}

	label {
		font-size: 14px;
		line-height: 22px;
		display: block;
	}
}
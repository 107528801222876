$apartment_preview_color: #4A4A4C;
$apartment_preview_margin: 50px;		
$apartment_preview_size: 160px;

/* The Circle */
/* ****************************** */
.apartment_circle {
	box-sizing: border-box;
	border: 2px solid $apartment_preview_color;
	padding: 20px;
	float: none;
	display: inline-block;
	position: relative;
	border-radius: 50%;
	text-align: center;
	background: transparent;
	transition: linear all 0.2s;
  font-family: $monts;

	width: $apartment_preview_size;
	height: $apartment_preview_size;

	margin-right: $apartment_preview_margin;
	margin-bottom: $apartment_preview_margin;

	@media (max-width: 1024px) {
    margin-right: 20px;
    margin-bottom: 20px;	
	}

	.ap_name, .ap_content, .ap_title {
    font-family: $monts;
		transition: linear color 0.3s;
		color: $apartment_preview_color;
		text-transform: uppercase;
		display: block;
		float: left;
		width: 100%;
		font-size: 11px;
		font-weight: bold;
		line-height: 2em;
	}

	.ap_name {
		font-size: 22px;
	}

	.ap_title {
		font-size: 66px;
		padding-top: 0;
		line-height: 1.8em;
	}
}

/* Listing Page */
/* ****************************** */
.apartment_listing_wrapper {
	padding: 80px 80px 20px;

	@media (max-width: 1024px) {
		padding: 40px;
	}

}

.apartment_listing {
	@media (max-width: 1024px) {
		h2, h3, h4 {
			text-align: center;
		}
	}

	.apartment_floor {

		@media (max-width: 1024px) {
			text-align: center;

			.apartment_circle {
				margin-right: 10px;
				margin-left: 10px;
			}
		}

		.apartment_preview {
			cursor: pointer;

			&:hover {
				background: $apartment_preview_color;
				color: #fff;

				.ap_name, .ap_content {
					color: #fff;
				}
			}

		}
	}
}

/* Filters */
/* ****************************** */
.apartment_filters {
	background: $second_prim;
	padding: 42px 0;
  text-align: center;
  margin-bottom: 10px;

  .button {
  	margin-left: 5px;
  	margin-right: 5px;

  	&.active {
	    color: $second_prim;
    	background-color: #fff;
  	}
  }
}

/* The Building SVG */
/* ****************************** */
.apartments_building_wrapper {
	padding: 154px 20px 100px;
  padding: 154px 10% 100px;
  background: $second_prim;
  box-sizing: border-box;
  color: #fff;
}

.apartments_building {
	background: $second_prim;
	position: relative;

	img {
		z-index: 1;
		position: relative;
		top: 2px;
		left: 0;

		@media (max-width:1250px) {
			top: 6px;
		}
	}

	.floors {
		position: relative;
		z-index: 2;
		/* margin-left: 10%; */
		/* margin-top: 4%; */
		margin-top: -100%;
    width: 100%;
    height: 100%;

    .open_floor_wrapper {
    	opacity: 0;
    	transition: 0.2s linear opacity;

			.open_floor {
				cursor: pointer;
				transition: linear outline 0.2s;
				fill:transparent;
				stroke:#ED1C24;
				stroke-width:3;
				stroke-linejoin:
				round;
				stroke-miterlimit:10;
			}
			text {
				font-family: "Montserrat", Arial, Helvetica, sans-serif;
		    text-transform: uppercase;
		    font-size: 40px;
		    fill: #fff;
		    color: #fff;
			}

			&:hover {
				opacity: 1;
			}

		}
	}
}

/* View Page */
/* ****************************** */
.apartment_floor_plans_wrapper {
	padding: 75px 15%;
	display: block;
	float: left;
	width: 100%;
	box-sizing: border-box;

	.apartment_floor_plans {
		position: relative;
	}
}

.apartment_marker {
	position: absolute;
	background: rgba(0,0,0,1);
	height: 30px;
	width: 30px;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	cursor: pointer;
	transition: linear all 0.2s;

	&:hover {
		background: rgba(0,0,0,0.4);
	}
}

.ap_v_top_bar {
	background: #f1f3f4;
	display: block;
	float: left;
	width: 100%;
  font-family: $monts;
  font-size: 16px;
  line-height: 2em;

  * {
	  font-family: $monts;
  }

  .apartment_circle {
  	margin: 40px 40px 40px 0;

  	@media (max-width: 1024px) {
  		margin: 40px auto 10px;
	    position: relative;
	    display: block;
	    float: none !important;
  	}

  }

	.ap_v_top_bar_padding {
		padding-top: 62px;
		padding-bottom: 62px;

		@media (max-width: 767px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	.quick_details {
		@media (max-width: 1024px) {
			text-align: center;
			width: 100%;
			padding-top: 10px;
		}
	}

	.quick_summary {
		@media (max-width: 767px) {
			text-align: center;
			padding-bottom: 40px;
		}
	}

}

.ap_v_details {
	float: left;
	display: block;
	width: 100%;
  padding: 75px;
  background: $second_prim;
  box-sizing: border-box;

	@media (max-width: 767px) {
		padding: 40px 25px;
	}

  &, p, h1, h2, h3, h4, h5 {
		color: #fff;

		@media (max-width: 767px) {
			text-align: center;
		}
  }
}

.close_floor_plans {
  margin-top: 40px;
  margin-left: 40px;
}

/* Sales Popup */
/* ****************************** */
.salesPopup {
	display: none;
	padding: 80px;

	@media(max-width: 600px) {
		padding: 40px;
	}


	h2 {
		letter-spacing: 4px;
		background: none;
		font-size: 22px;
		text-align: left;
	}

	.submit_button {
		width: 100%;
	    height: 88px;
	    font-size: 16px;
	}

	input[type='checkbox'] {
		width: auto;
	}

	input[type="text"], input[type="email"], input[type="number"], input[type="url"], input[type="password"], textarea, select {
		margin-bottom: 7px;
	}

	label {
		margin-left: 18px;
    	display: block;
   	    line-height: 27px;

    	input[type='checkbox'] {
    		margin-left: -18px;
    	}
	}
}

.boxOpen .salesPopup {
	display: block;
}

.boxOpen #cboxLoadedContent {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
/* ================================= */
/* Home */
/* ================================= */


#content ul#homeNav {
  margin:        0 0 15px;
  padding:       7px;
  background:    #CCCCCC;
  border-radius: 8px;
}

#content #homeNav li {
  margin:      0;
  padding:     1px 0;
  text-align:  center;
  text-indent: 0;
  margin-left: 0 !important;
}

#content #homeNav li:before {
  content: '' !important;
}

#homeNav a {
  display:          block;
  background:       #EEEEEE;
  background-image: linear-gradient(bottom, rgb(50, 50, 50) 0%, rgb(70, 70, 70) 100%);
  background-image: -o-linear-gradient(bottom, rgb(50, 50, 50) 0%, rgb(70, 70, 70) 100%);
  background-image: -moz-linear-gradient(bottom, rgb(50, 50, 50) 0%, rgb(70, 70, 70) 100%);
  background-image: -webkit-linear-gradient(bottom, rgb(50, 50, 50) 0%, rgb(70, 70, 70) 100%);
  background-image: -ms-linear-gradient(bottom, rgb(50, 50, 50) 0%, rgb(70, 70, 70) 100%);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgb(50, 50, 50)), color-stop(1, rgb(70, 70, 70)));
  padding:          13px 15px;
  text-decoration:  none;
  margin:           0;
  color:            #FFFFFF;
  font-weight:      bold;
  text-align:       center;
  text-transform:   uppercase;
  text-shadow:      -1px -1px 3px rgba(0, 0, 0, 0.2);
}

#homeNav a:active, #homeNav a:focus, #homeNav a:hover {
  box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.08), inset 2px 2px 5px rgba(0, 0, 0, 0.5) !important;
  outline:    none !important;
}

#homeNav li:first-child a {
  border-top-left-radius:  5px;
  border-top-right-radius: 5px;
}

#homeNav li:last-child a {
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
}

/* Home Page Banner */

#homeBanner, #homeBanner ul.slides > li, #homeBanner ul.slides > li .vid_background, #homeBanner .vert_center_container {
  height: 800px;

   @media(max-width: $grid_s) {
      height: 500px;
   }
}

#footerBanner {
    margin-bottom: 10px;

    h2, p {
      color: #ffffff;
    }
}

#homeBanner {
  min-height: 300px;
  margin-bottom: 10px;
  color: #fff;
  background: no-repeat center center #000;
  background-size: cover;

  ul.slides {
    height: 100%;
  }
  ul.slides > li {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;

    .vid_background {
      position: absolute!important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}
.banner_text_outer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media(max-width: $grid_s) {
    top: 33px;
  }


  .banner_text_wrapper {
      top: 0;
      margin-top: 51px;
      position: relative;
      @media(max-width: $grid_s) {
        margin-top: 0;
      }
      .banner_text {
        text-align: center;
        color: #fff;
        z-index: 99;

        > img {
          width: auto;
          display: inline-block;
          margin-bottom: 20px;

          
          @media(max-width: $grid_m) {
            max-width: 400px; 
            margin-bottom: 30px
          }
          @media(max-width: $grid_s) {
            max-width: 360px; 
            margin-bottom: 20px
          }
          @media(max-width: $grid_xs) {
            max-width: 270px; 
            margin-bottom: 10px
          }
        }
        .button  img {
          display: inline-block;
          margin-top: -3px;
          width: auto;
        }

        p {
          font-family: $brandon_regular_i;
          font-size: 32px;
          font-weight: normal;
          line-height: 44px;
           @media(max-width: 1217px) {
              font-size: 28px;
              font-weight: normal;
              line-height: 41px;
            }
           @media(max-width: $grid_m) {
              font-size: 22px;
              font-weight: normal;
              line-height: 30px;
           }
           @media(max-width: $grid_s) {
            font-size: 19px;
            font-weight: normal;
            line-height: 24px;
           }
        }
        a.banner_phone {
          display: block;
          color: #fff;
          width: 100%;
          margin: 10px 0;
          font-size: 32px;
          font-family: $brandon_black;
        }
        .button {
          margin-top: 20px;
        }
      }
    }
  }

// Secondary Banner

#secondaryBanner, #secondaryBanner .secondary_banner_box, .secondar_banner_container .overlay_trans, .secondar_banner_container .overlay_container, .secondar_banner_container .banner_content,
.secondar_banner_container .window_image{ 
      height: 400px;

      @media(min-width: $grid_xl) { 
        height: 447px;
      }
      @media(max-width: $grid_m) { 
        height: 400px;
      }

      @media(max-width: $grid_xs) { 
        height: auto;
      }
}
@media(max-width: $grid_xs) { 
  .secondar_banner_container .window_image, .secondar_banner_container #secondaryBanner .secondary_banner_box{
    height: 400px;
  }
}

.secondar_banner_container {
  position:relative;

  #secondaryBanner {

      .secondary_banner_box {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

  }

  .overlay_container {
    position: absolute;
    z-index: 99;
    top:0;
    width: 100%;

    @media(max-width: $grid_xs) {
      position: static;
    }
    .overlay_trans {
      background: rgba(255,255,255,0.7);
      width: 16.6667%;
      float:left;

      @media(max-width: $grid_m) {
        width:0%;
      }
    }
    .window_image {
      width: 33.333333333%;
      float:left;

      @media(max-width: $grid_m) {
         width: 50%
      }
      @media(max-width: $grid_s) {
        width: 40%;
      }
      @media(max-width: $grid_xs) {
        width: 100%;
        display: none;
      }
    }
    .banner_content {
      width: 33.333333333%;
      float:left;
      @include box-sizing();
      overflow:hidden;
      background: #F2F2F2;

      @media(max-width: $grid_m) {
         width: 50%
      }
      @media(max-width: $grid_s) {
        width: 60%;
      }
      @media(max-width: $grid_xs) {
        width: 100%;
        text-align: left;
        padding: 20px 0;
      }

      .inner_padding {
        width: 100%;
        margin: 0 auto;
        padding: 20px 10%;
        @include box-sizing();


        h2 {
          margin-bottom: 15px;
        }

        .button {
          float: right;
          margin-top: 25px;

          @media(max-width: $grid_xs) {
            margin: 30px auto;
            float: none;
            display: block;
            text-align: center;
            max-width: 243px;
          }
        }
      }
    }

  }

}
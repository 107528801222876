// The Location Banner

.location_block {
	height: 649px;

	@media(max-width: $grid_m) {
		height: 500px;
	}
	@media(max-width: $grid_xs) {
		height: 600px;
	}
}
.location_block {
	
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;

	.blue_slider {
		position:absolute;
		width: 30%;

		background: rgba(11, 30, 62, 0.77);
		@include box-sizing();
		padding: 40px 3%;
		left:0;
		top: 0;
		bottom: 0;
		@include transition(all, .8s, ease-in-out);

		.lt-ie9 & {
			background: url(/images/backgrounds/blue-rgba.png) repeat;
		}

		&.right {
			left:70%;
		}

		@media(max-width: $grid_m) {
			width: 100%;
			overflow: hidden;  
			padding: 40px 8%;
			text-align: left;

			&.right {
				width: 25%;
				left: 75%;
				background: rgba(11, 30, 62, 0);
				.directional_arrow  {
					width: 100%!important;
					  margin-right: 10px;
				}

				h2 {
					display: none;
				}
				.lb_content {
					display: none;
				}
			}

		}

		.lb_header {
			width: 100%;
			float:left;
			margin-bottom: 60px;

			@media(max-width: 1282px) {
				margin-bottom: 30px;
			}

			h2 {
				font-family: $brandon_bold;
				line-height: 56px;
				float:left;

				width: 75%;

			
				@media(max-width: $grid_m) {
					line-height: 41px;
				}
			}
			.directional_arrow {
				width: 25%;
				margin-top: 15px;
				text-align: right;
				float: right;

				@media(max-width: 1282px) {
					margin-top: 0;
				}
				@media(max-width: $grid_m) {
					display: none;
				}
				img {
					@include transition();
					&:hover {
						opacity: 0.8;
					}
					&:active {
						@include transition(all,2.5s, ease);
						-ms-transform: scaleX(-1); /* IE 9 */
						-webkit-transform: scaleX(-1); /* Safari */
						transform: scaleX(-1);
					}
				}

				img.right {
					-ms-transform: scaleX(-1); /* IE 9 */
					-webkit-transform: scaleX(-1); /* Safari */
					transform: scaleX(-1);
					&:hover {
						opacity: 0.8;
					}

					&:active {
						@include transition(all, 5s, ease);
						-ms-transform: scaleX(1); /* IE 9 */
						-webkit-transform: scaleX(1); /* Safari */
						transform: scaleX(1);
					}
				}
			}
		}

		.lb_content {

			.button {
				margin-top: 50px;
				float: right;

				@media(max-width: $grid_m) {
					margin: 30px auto;
					float: none;
					display: block;
					max-width: 199px;
				}
			}
		}
	}
}



// The Building Block


.the_building_block {
	width: 100%;
	height: 838px;

		@media(max-width: 1300px) {
		height: 740px!important;
		}
		@media(max-width: $grid_m) {
		height: auto!important;
		}
		@media(max-width: $grid_s) {
		height: auto!important;
		}

	.main_image, .secondary_images .half_image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position:  center center
	}

	.secondary_images .half_image { 
		height: 50%;
		width: 100%;

		@media(max-width: $grid_s) {
			height: 200px;
		}
	}
	.main_image, .secondary_images, .tbb_content {
		height: 100%;
		width: 33.333333333%;
		float: left;

		@media(max-width: $grid_m) {
			width: 100%;
			height: auto;
		}
	}
	.main_image {
		@media(max-width: $grid_s) {
			height: 350px;
		}
	}

	.tbb_content {

		@media(max-width: $grid_s) {
  			padding: 50px 8%;
		}

		@include box-sizing();
		padding: 60px 4%;
		background: $teriary_color;

		h2 {
			margin-bottom: 50px;
			
			@media(max-width: $grid_s) {
			    margin-bottom: 30px;
			}
		}

		.button {
			margin-top: 60px;
			float: right;

			@media(max-width: $grid_s) {
			  margin: 30px auto;
			  float: none;
			  display: block;
			  max-width: 199px;

			}
		}
	}
}

#theBuilding, #theBuilding ul.slides > li {
	height: 610px;
	width: 100%;
}

#theBuilding {
	display: none;
	@media(max-width: $grid_m) {
		display: block;
	}

	li {
		background-size: cover;
		background-position: center center;
	}
}

// Four Columns Text

.column_4_text {

	padding: 50px 4%;
	background: #173154;
	font-family: $brandon_regular;

	&.white {
		background: #fff;
	}
	&.offwhite {
		background: #F2F2F2;
	}

	@media(max-width: $grid_s) {
		  padding: 50px 8%;
	}

}

// Featured News

.featured_news {

	.featured_news_box {
		width: 25%;
		position: relative;
		float: left;
		height:400px;
		overflow: hidden;
		background-size: cover;
		background-position: center;

		@media(max-width: $grid_m) {
			width: 50%;
		}
		@media(max-width: $grid_s) {
			width: 50%;
			text-align: center;
		}
		@media(max-width: $grid_xs) {
			width: 100%;
			height: auto;
		}

		.news_content { 

			.news_header {

				position:relative;
				h3 {
					width: 75%;
					float: left;
					text-align: left;
					font-size: 22px;
					line-height: 26px;
					margin-bottom: 25px;
					font-family: $brandon_bold;

					@media(max-width: 1128px) {
						width: 100%;
						text-align: center;
						margin-bottom: 5px;
					}
				}
				.date {
					float: left;
					width: 22%;
					text-align: right;
					margin-left: 3%;
					font-size: 12px;
					@media(max-width: 1128px) {
						width: 100%;
						text-align: center;
						margin-left: 0;
					}
				}
			}

			position: relative;
			margin-top: 35%;
			height: 100%;
			padding:40px;
			background: rgba(28, 37, 80, 0.8);
			opacity: 1;
			@include box-sizing();
			@include transition();

			
			.lt-ie9 & {
				background: url(/images/backgrounds/blue-rgba.png) repeat;
			}


			@media(max-width: $grid_m) {
				opacity: 1;
				margin-top: 0%;
			}


			p {
				font-family: $brandon_regular;
				font-size: 18px;
				line-height: 23px;
				margin-bottom: 20px;
			}
			.button {
				
				position: absolute;
				top: auto;
				bottom: 35px;
				right: 40px;
				height: 50px;
				@media(max-width: 1258px) {
					font-size: 16px;
					padding: 5px 10px;
					  left: 40px;
					  text-align: center;
				}

				@media(max-width: $grid_s) {
  					padding: 5px 10px;
  					font-size: 15px;
  					margin-top: 20px;
  					position: static;
				}
			}
		}

		&:hover .news_content{
			opacity: 1;
			background: rgba(28, 37, 80, 0.8);
			margin-top: 0%;
		}
	}

}

.button_container {

	@media(max-width: $grid_s) {
		text-align: center;
	}

}




// Text Image Block

.image_text_block {
 	padding: 0 0 10px 0;
 	float: left;
 	width: 100%;
	.main_image {
		@include box-sizing();
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 600px;

		&.contain {
			background-size: contain;
			background-position: right;
		}
		&.right.contain {
			background-position: left;
			background-position: left;
		}

		@media(max-width: $grid_m) {
			height: 400px;
			background-position: center!important;
		}
		@media(max-width: $grid_s) {
			height: 300px;
			background-position: center!important;
		}
	}

	p {
		margin-bottom: 26px;
	}
	p:last-of-type {
		margin-bottom: 7px;
	}

	.itb_content {
		height: 600px;
		overflow-y: auto;
		@include box-sizing();
		padding: 80px 85px 10px 85px;
		font-family: $stempel_roman;
		font-weight: normal;

		@media(max-width: $grid_m) {
			height: auto;
			padding: 60px 65px 60px 65px;
		}

		@media(max-width: $grid_s) {
			padding: 40px 25px;
		}


		.button  {
			margin-top: 30px;


		}

		h2 {
			margin-bottom: 42px;
			line-height: 35px;
			padding-bottom: 42px;
			background: url('/images/backgrounds/header_line.gif') bottom left no-repeat;
			letter-spacing: 4px;

			
			@media(max-width: $grid_s) {
				text-align: center;
				background-position: center bottom;
			}
		}

		h4 {
			font-family: $monts;
			font-size: 11px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 37px;
			letter-spacing: 2px;

			@media(max-width: $grid_s) {
				text-align: center;
			}
		}

		p {
			@media(max-width: $grid_s) {
				text-align: center;
			}
		}

		&.blue {
			background-color: $prim_color;
			color: #fff;
			h2 {
				color: #fff;
			}
		}

		&.white {
			background-color: #fff;
		}

		&.offwhite {
			background-color: #F2F2F2;
		}

	}
	
	// sizes

	.twothird {
		width: 66.666%;

		@media(max-width: 1433px) {
			width: 53.666%;
		}
		@media(max-width: $grid_m) {
			width: 100%;
		}
	}
	.onethird {
		width: 33.333%;

		@media(max-width: 1433px) {
			width: 46.333%;
		}
		@media(max-width: $grid_m) {
			width: 100%;
		}
	}
	.half {
		width: 50%;
		@media(max-width: $grid_m) {
			width: 100%;
		}
	}

	// positions

	.left {
		float: left;
	}
	.right {
		float: right;
	}

}

.parallax {
    overflow: hidden;
    margin-bottom: 10px;
    background-position: center center;
    height: 199px;
}

.parallax_heading {
    position: relative;
}

.get_in_touch {
	background: #414042;
	margin-bottom: 10px;

	h2 {
		font-size: 31px;
		font-family: $monts;
		font-weight: 700;
		color: #f1f3f4;
		background: none;
		text-align: center;
		margin: 0;
		padding: 0; 
		text-transform: none;
	}

	h4 {
		margin-top: 0;
		padding: 0;
		font-size: 22px;
		color: #f1f3f4;
		text-align: center;
		font-family: $monts;
		margin-top: 10px;
	    line-height: 24px;
	}

	.vcentered_outer {
        display: table-cell;
        height: 319px; /* !! Needs some sort of height */

        @media(max-width: 728px) {
        	height: 350px;
        }

         @media(max-width: 728px) {
        	height: 370px;
        }

    }

    .vcentered_item {
        height: 187px; /* !! Need to apply height */
        margin: auto;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        display: table-cell;
        vertical-align: middle;

        @media(max-width: 728px) {
        	height: 264px;
        }
        @media(max-width: 728px) {
        	height: 284px;
        }

    }

    .git_social {
    	text-align: center;
    	margin-top: 39px;
    	ul {
	    	li {
	    		display: inline-block;
	    		margin-left: 20px;
	    		margin-right: 20px;
	    		text-indent: 0px;

	    		@media(max-width: 728px) {
        			margin-bottom: 10px;
        		}

        		@media(max-width: 380px) {
		        	margin-right: 3px;
		        	margin-left: 3px;
		        }

	    	}
	    }
	}

}

.stay_in_touch {
	background: #f1f3f4;
	margin-bottom: 10px;

	h2 {
		font-size: 31px;
		font-family: $monts;
		font-weight: 700;
		color: #414042;
		background: none;
		text-align: center;
		margin: 0;
		padding: 0; 
		text-transform: none;
	}

	h4 {
		margin-top: 0;
		padding: 0;
		font-size: 22px;
		color: #414042;
		text-align: center;
		font-family: $monts;
		margin-top: 10px;
	    line-height: 24px;
	}

}

    .sit_form {
    	text-align: center;
   		margin-top: 39px; 

   		input, .button_full_width {
   			padding: 30px 19px;
   			font-family: $monts;
   			font-size: 22px;
   			background-color: #e9ebec;
   			border: 2px solid #e9ebec;
   			line-height: normal;
   		
   			@media(max-width: $grid_m) {
   				margin-top: 20px;
   		
   			}

   		}
   		

   		.button_full_width {
   			padding: 33px 19px;
   			font-size: 16px;
   			color:#414042;
   			background: #f1f3f4;
   			background: transparent;

   			&:hover {
   				background-color: #414042;
   				color: #ffffff !important;
   			}
   		}
   		button {
   			i {
   				margin-left: 10px;
   			}
   		}

   	}

   	.stay_in_touch {

   		.vcentered_outer {
        display: table-cell;
        height: 333px; /* !! Needs some sort of height */

	        @media(max-width: 767px) {
	        	height: 520px;
	        }
	        @media(max-width: 480px) {
	        	height: 570px;
	        }
	    }

	    .vcentered_item {
	        height: 213px; /* !! Need to apply height */
	        margin: auto;
	        position: absolute;
	        top: 0; left: 0; bottom: 0; right: 0;
	        display: table-cell;
	        vertical-align: middle;

	        @media(max-width: 1024px) {
	        	height: 243px;
	        }

	        @media(max-width: 767px) {
	        	height: 475px;
	        }
	        @media(max-width: 480px) {
	        	height: 508px;
	        }
	    }
    }

    .captcha_area {
		@media(max-width: 1024px) {
        	margin-top: 20px;
        }
	}

	.expression_of_interest {
		background-color: #f1f3f4;
		margin-bottom: 10px;

		h2 {
			font-size: 31px;
			font-family: $monts;
			font-weight: 700;
			color: #414042;
			background: none;
			text-align: center;
			margin: 0;
			padding: 0; 
			text-transform: none;
		}

		h4 {
			margin-top: 0;
			padding: 0;
			font-size: 22px;
			color: #414042;
			text-align: center;
			font-family: $monts;
			margin-top: 10px;
		    line-height: 24px;
		}

		.expression_form {
		    padding-top: 75px;
		    padding-bottom: 74px;
		}

	}


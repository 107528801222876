// Footer FORM
.footer_contact_form_outer {
  background: #F2F3F5;
}
.footer_contact_form {
  padding: 80px 0 60px 6%;


  @media(max-width: $grid_m) {
    padding: 40px 25px;
    text-align: center;

    form {
      margin-top: 30px;
    }
  }

  h2 {
    margin-bottom: 30px;
    color: #778898;
  }
  .form_row.button_container {
    text-align: left;
    margin-top: 40px;
    margin-right: 0;

     @media(max-width: $grid_s) {
      text-align: center;
     }
  }
}
.footer_contact_details {
  padding: 80px 4%;

  @media(max-width: $grid_m) {
      padding: 20px 25px 35px;
      text-align: center;
  }

  h2 {
    margin-bottom: 20px;
    color: #778898;
  }
}

/* ================================= */
/* Footer */
/* ================================= */
#footer {
  clear:            both;
  background-color:#414143;
  display:          block;
  width:            100%;
  float:            left;
  padding:          70px 0 0 0;

  @media(max-width: $grid_s) {
    padding:          30px 0 0 0;
  } 

  a {
    color:     #fff;
    font-size: 11px;

    &:hover {
      color: #ca5a5d;
    }
  }

  li {
    display:    inline;
    list-style: none;
    margin:     0 12px 0 0;
  }

  /* Footer Top */
  /* ================================= */
  .footer_top {
    padding: 15px 0;
    margin: 0 0 30px 0;
    background: #ccc;

    .footer_social {
      li {
        display: block;
        float: left;
        margin-top: 3px;

        @media (max-width: $grid_s) {
          display: inline-block;
          float: none;
        }
      }

      @media (max-width: $grid_s) {
        text-align: center;
      }

      a {
        background: $prim_color;
        padding: 10px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: block;
        float: left;
        text-align: center;
        font-size: 20px;
        color: #fff;
        transition: background-color linear 0.2s;

        &:hover {
          background: darken($prim_color, 10%);
        }

        &.footer_social_facebook:hover    { background: #3C5A98; }
        &.footer_social_twitter:hover     { background: #20B8FF; }
        &.footer_social_instagram:hover   { background: #A77B5E; }
        &.footer_social_google_plus:hover { background: #DA4835; }
      }
    }

    .footer_subscribe_title {
      font-size: 0;
      height: 50px;
      padding-right: 10px;
      @include box-sizing();

      .vert_align {
        font-size: 14px;
        font-weight: bold;
      }

      @media (max-width: $grid_s) {
        text-align: center;
      }
    }
  }

  /* Footer Bottom */
  /* ================================= */
  .footer_bottom {
    background: #333132;
    text-align: right;
    margin-top: 58px;
    padding: 20px 4%;

    @media (max-width: $grid_xs) {
      padding: 40px 0px;
    }
    @media (max-width: $grid_s) {
      margin-top: 54px;
    }

    .social_ic {
      
       @media (max-width: $grid_xs) {
          margin-top: 10px;
        }

      a {
        font-size: 22px;
        margin-left: 12px;

        @media (max-width: $grid_xs) {
          font-size: 20px;
        }

      }
      a:first-of-type {
        margin-left: 0px;
      }
    }

    @media (max-width: $grid_s) {
      text-align: center;
    }

    &, a {
      color: #939393;
      font-family: $brandon_regular;
      line-height: 20px;
      text-transform: normal;
      font-size: 16px;

      @media (max-width: $grid_s) {
        font-size: 12px;
      }

      @media (max-width: $grid_xs) {
        font-size: 15px;
        line-height: normal;
      }

    }



    a:hover {
      color: #fff;
    }
    .copyright_container {
      text-align: left;

      @media (max-width: $grid_s) {
        text-align: center;
        margin-bottom: 20px;
      }

      ul.footerNav {
        text-align: left;
        display: inline-block;;
        margin-right: 20px;
        margin-bottom: 0!important;
        float: left;
      
       @media (min-width: $grid_s) and (max-width: $grid_m)  {
          margin-left: 0px;
       }

      @media (max-width: $grid_s) {
        text-align: center;
        float: none;
      }

      @media (max-width: $grid_xs) {
        display: block;
        font-size: 15px;
        margin-top: 30px !important;
      }

        li {
          margin-right: 0;
          &:before {
            content: ' | ';
            margin: 0 5px;
          }
          &:first-child:before {
            display: none;
          }
        }
      }
    }
  }

  /* Footer Left */
  /* ================================= */
  .footer_left {
    text-align: center;
    float: left;
    width: 100%;
    padding: 0 7%;
    @include box-sizing();

    h2 {
      font-size:   13px;
      font-weight: bold;
      color:       #fff;
    }

    li {
      display:    inline-block;
      list-style: none;
      margin:     0 12px  0;
      text-align: left;
      width:      auto;
    }

    li, li a {
      text-transform: uppercase;
      font-family: $monts;
      line-height: 20px;
      font-size: 14px;
      margin: 0 10px 9px 10px;
      font-weight: bold;

      @media (max-width: $grid_s) {
        display: block;
        text-align: center;
        margin: 7px;
        font-size: 17px;
        line-height: normal;
      }
    }
  }

  .footer_desc {
    float:left;
    color: #fff;
    width: 65%;
    @include box-sizing();

    @media (max-width: $grid_s) {
        font-size: 13px; 
        line-height: 22px;
        padding-right: 20px; 
    }

    @media (max-width: $grid_xs) {
        width: 60%;
    }

    h2 {
      font-family: $brandon_bold;
      line-height: 22px;
      font-size: 18px;
      color: #fff;
     @media (max-width: $grid_s) {
        font-size: 14px;
      }
    }
  }


  .footer_logo {
    margin-top: 66px;
    text-align: center;

    @media (max-width: $grid_s) {
      margin-top: 22px;
    }

  }

  /* Footer Right */
  /* ================================= */
  .footer_right {
    font-size:   11px;
    line-height: 16px;
    text-align:  right;
    padding-right: 7%;
    margin-top: 91px;

    @media (max-width: $grid_m) {
      text-align: center;
      padding-right: 0;
      margin-top: 30px;

      img {
        width: 60%;
        max-width: 250px;
      }
    }

    ul {
      margin-bottom: 10px;

      li {
        display:    inline-block;
        list-style: none;
        margin:     0 0 0 11px;
      }
    }
  }

  .site_by {
    display: block;
    padding: 10px 0 0;

    img {
      display: inline-block;
      margin:  -11px 0 0 2px;
    }
  }


  .byLine {
    white-space: nowrap;

    span {
      position: relative; 
      display:  inline-block;
      top:      -6px;
      margin:   0 3px 0;
    }
  }

  .footerNav {
    margin-bottom: 10px;
  }
}



.simform {
	position: relative;
	margin: 6px auto;
	width: 100%;
	text-align: left;
	font-size: 2.5em;
}

.simform .submit {
	display: none;
}

/* Question list style */
.simform ol {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	-webkit-transition: height 0.4s;
	transition: height 0.4s;
}

.simform ol:before {
	content: '';
	background-color: rgba(0,0,0,0.1);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 92px;
}

.questions {
	margin: 0px !important;
}

.questions li {
	z-index: 100;
	position: relative;
	visibility: hidden;
	height: 0;
	-webkit-transition: visibility 0s 0.4s, height 0s 0.4s;
	transition: visibility 0s 0.4s, height 0s 0.4s;
}

.questions li.current,
.no-js .questions li {
	visibility: visible;
	height: auto;
	-webkit-transition: none;
	transition: none;
}

/* Labels */
.questions li > span {
	display: block;
	overflow: hidden;
	font-family: $monts;
	font-size: 22px;
	text-align: center;
}

.questions li > span label {
	display: block;
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);

}

.questions li.current > span label,
.no-js .questions li > span label {
	-webkit-transition: none;
	transition: none;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.show-next .questions li.current > span label {
	-webkit-animation: moveUpFromDown 0.4s both;
	animation: moveUpFromDown 0.4s both;
}

@-webkit-keyframes moveUpFromDown {
	from { -webkit-transform: translateY(100%); }
	to { -webkit-transform: translateY(0); }
}

@keyframes moveUpFromDown {
	from { -webkit-transform: translateY(100%); transform: translateY(100%); }
	to { -webkit-transform: translateY(0); transform: translateY(0); }
}

.questions input:focus {
	border: none !important;
}

/* Input field */
.questions input {
	display: block;
    margin: 32px 0 0 0;
    padding: 30px;
    width: 100%;
    border: none;
    background: transparent;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1em;
    line-height: 1;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 22px;
    background-color: #e9ebec;
    height: 92px;
    -webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}

.questions .current input,
.no-js .questions input {
	opacity: 1;
}

.questions input:focus,
.simform button:focus {
	outline: none;
}

/* Next question button */
.next {
	position: absolute;
	right: 0;
	bottom: 2.15em; /* padding-bottom of form plus progress bar height */
	display: block;
	padding: 0;
	width: 2em;
	height:92px;
	border: none;
	background: none;
	color: rgba(0,0,0,0.4);
	text-align: center;
	opacity: 0;
	z-index: 100;
	cursor: pointer;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transform: translateX(-20%);
	transform: translateX(-20%);
	pointer-events: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	top: 63px;
	font-size: 50px;
}

.next:hover {
	color: rgba(0,0,0,0.5);
	background-color: #d9dbdc !important;
	border: none !important;
}

.next:active {
	top: 63px;
	border: none;
}

.next::after {
	position: absolute;
	top: 37px;
	left: 0;
	width: 100%;
	height: 100%;
	content: "\f061";
	text-transform: none;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	font-family: 'FontAwesome';
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.next.show {
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0);
	pointer-events: auto;
}

/* Progress bar */
.simform .progress {
	width: 0%;
	height: 10px;
	background: rgba(0,0,0,0.3);
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
}

.simform .progress::before {
	position: absolute;
	top: auto;
	width: 100%;
	height: inherit;
	background: rgba(0,0,0,0.05);
	content: '';
}

/* Number indicator */
.simform .number {
	position: absolute;
	right: 0;
	overflow: hidden;
	margin: 0.4em 0;
	width: 3em;
	font-weight: 700;
	font-size: 0.4em;
}

.simform .number:after {
	position: absolute;
	left: 50%;
	content: '/';
	opacity: 0.4;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.simform .number span {
	float: right;
	width: 40%;
	text-align: center;
}

.simform .number .number-current {
	float: left;
}

.simform .number-next {
	position: absolute;
	left: 0;
}

.simform.show-next .number-current {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

.simform.show-next .number-next {
	-webkit-animation: moveUpFromDown 0.4s both;
	animation: moveUpFromDown 0.4s both;
}

/* Error and final message */
.simform .error-message,
.simform .final-message {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.4s;
	transition: opacity 0.4s;
}

.simform .error-message {
	padding: 0.4em 3.5em 0 0;
	width: 100%;
	color: rgba(0,0,0,0.7);
	font-style: italic;
	font-size: 0.4em;
	box-sizing:border-box;
}

.final-message {
	top: 50%;
	left: 0;
	padding: 0.5em;
	width: 100%;
	text-align: center;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	box-sizing:border-box;
}

.error-message.show,
.final-message.show {
	visibility: visible;
	opacity: 1;
}

.final-message.show {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

/* Final hiding of form / showing message */
.simform-inner.hide {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.3s, visibility 0s 0.3s;
	transition: opacity 0.3s, visibility 0s 0.3s;
}

/* No JS Fallback */
.no-js .simform {
	font-size: 1.75em;
}

.no-js .questions li {
	padding: 0 0 2em;
}

.no-js .simform .submit {
	display: block;
	float: right;
	padding: 10px 20px;
	border: none;
	background: rgba(0,0,0,0.3);
	color: rgba(0,0,0,0.4);
}

.controls {
	background-color: #cbcdcd;
}

.no-js .simform .controls {
	display: none;
}

/* Remove IE clear cross */
input[type=text]::-ms-clear {
    display: none;
}

